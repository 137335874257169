<template>
  <div class="section">
    <div class="container custom-container">
      <div class="field has-addons is-pulled-right">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Enter title or compound"
            v-model="searchString"
          />
        </div>
        <div class="control">
          <button class="button is-primary">
            <span class="icon">
              <font-awesome-icon icon="search" />
            </span>
          </button>
        </div>
      </div>
      <h1 class="title">
        <Loader v-bind:isLoading="isLoading" />&nbsp;Activity
      </h1>
      <table class="ist-table-striped is-fullwidth">
        <thead>
          <tr>
            <th
              v-for="(col, index) in columns"
              v-bind:key="col[0]"
              v-on:click="sortBy(col[1])"
              class="sortable"
              v-bind:class="{ left: index < 3 }"
            >
              {{ col[0] }}
              <span v-if="col[1] === 'risk'" class="icon is-small">
                <font-awesome-icon
                  title="Outcome follows classification in Llopis et al. 2020."
                  icon="info-circle"
                  class="help-icon"
                />
              </span>
              <SortedIcon
                v-if="sortKey == col[1]"
                v-bind:direction="sortDirection"
                v-bind:currentSort="sortKey"
                v-bind:thisSort="col[1]"
              />
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in sortedTableRows">
            <tr
              v-if="'_id' in row"
              v-bind:key="row._id"
              v-bind:class="{ 'has-text-grey-light': row.isLoading }"
            >
              <td class="left" v-if="row.isSingle">{{ row.name }}</td>
              <td
                v-else
                class="clickable left"
                v-on:click="toggleExpand(row._id)"
              >
                <div style="display: flex">
                  <span class="icon">
                    <font-awesome-icon
                      icon="chevron-down"
                      v-if="row.isExpanded"
                    />
                    <font-awesome-icon
                      icon="chevron-right"
                      v-if="!row.isExpanded"
                    />
                  </span>
                  <span>{{ row.name }}</span>
                </div>
              </td>
              <td class="left">{{ row.compounds }}</td>
              <td class="left">{{ row.concentrations }}</td>
              <td>
                <div class="flex-vertical-center-parent" v-if="row.isSingle">
                  <font-awesome-icon
                    class="channel-warning-icon"
                    icon="exclamation-triangle"
                    v-if="row.error"
                  />
                  <RiskIcon v-else v-bind:risk="row.risk" />
                </div>
              </td>
              <td>{{ row.created_at | formatDate }}</td>
              <td class="has-text-grey">
                <font-awesome-icon
                  v-if="row.status == 'active'"
                  icon="hourglass-half"
                />
                <font-awesome-icon
                  v-else-if="row.status === 'completed'"
                  icon="check"
                />
              </td>
              <td>
                <router-link
                  v-if="row.status == 'completed'"
                  :to="{ name: 'job', params: { id: row._id } }"
                  class="is-primary is-size-7"
                  >VIEW</router-link
                >&nbsp;&vert;&nbsp;
                <a
                  href="#"
                  class="is-size-7"
                  v-on:click="toggleDeleteConfirmationModal(row._id)"
                  >DELETE</a
                >
              </td>
            </tr>
            <tr v-else v-bind:key="row.name + index">
              <td class="left">{{ row.name }}</td>
              <td class="left">{{ row.compounds }}</td>
              <td class="left">{{ row.concentrations }}</td>
              <td>
                <font-awesome-icon
                  class="channel-warning-icon"
                  icon="exclamation-triangle"
                  v-if="row.error"
                />
                <RiskIcon v-else v-bind:risk="row.risk" />
              </td>
              <td>{{ row.created_at | formatDate }}</td>
              <td class="has-text-grey">
                <font-awesome-icon
                  v-if="row.status == 'active'"
                  icon="hourglass-half"
                />
                <font-awesome-icon
                  v-else-if="row.status === 'completed'"
                  icon="check"
                />
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
      <p class="legend">
        Legend:
        <RiskIcon v-bind:risk="1" withSpace />TdP- (safe)
        <RiskIcon v-bind:risk="4" withSpace />TdP- (probably safe)
        <RiskIcon v-bind:risk="6" withSpace />TdP+ (probably unsafe)
        <RiskIcon v-bind:risk="9" withSpace />TdP+ (unsafe)
        <font-awesome-icon
          class="channel-warning-icon warning-icon-margin"
          icon="exclamation-triangle"
        />Data out of range
      </p>
      <p>
        <button class="button is-primary" v-on:click="toggleExportModal">
          Export data
        </button>
      </p>
      <!-- modal for simulation deletion -->
      <div class="modal" v-bind:class="{ 'is-active': showingDeleteModal }">
        <div class="modal-background" />
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm</p>
            <button
              class="delete"
              aria-label="close"
              v-on:click="toggleDeleteConfirmationModal"
            />
          </header>
          <section class="modal-card-body">
            Are you sure you want to delete this computation?
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" v-on:click="deleteSimulation">
              Delete
            </button>
            <button class="button" v-on:click="toggleDeleteConfirmationModal">
              Cancel
            </button>
          </footer>
        </div>
      </div>
      <!-- modal for export of simulation data -->
      <div class="modal" v-bind:class="{ 'is-active': showingExportModal }">
        <div class="modal-background" />
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Export Data</p>
            <button
              class="delete"
              aria-label="close"
              v-on:click="toggleExportModal"
            />
          </header>
          <section class="modal-card-body">
            <p v-if="searchString === ''">Exporting all computations.</p>
            <p v-else>
              Exporting computations matching &ldquo;{{ searchString }}&rdquo;.
            </p>
            <p>Chose format.</p>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-primary"
              v-on:click="exportCSV"
              :disabled="isExportLoading"
            >
              Export to CSV
            </button>
            <button
              class="button is-primary"
              v-on:click="exportJSON"
              :disabled="isExportLoading"
            >
              Export to JSON
            </button>
            <span v-if="isExportLoading">
              <Loader v-bind:isLoading="isExportLoading" />&nbsp;Preparing...
            </span>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import config from '@/config/apollo-config';
import Loader from '@/components/Loader.vue';
import SortedIcon from '@/components/SortedIcon.vue';
import RiskIcon from '@/components/RiskIcon.vue';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

function joinAndAbbreviate(arr, localeNumber) {
  const maximumLength = localeNumber ? 10 : 20; // plus ellipsis
  let text = '';
  for (let i = 0; i < arr.length; i += 1) {
    const el = localeNumber ? arr[i].toLocaleString('en-US') : arr[i];
    if (text === '') {
      text += el;
    } else if (text.length >= maximumLength) {
      text += '; ...';
      break;
    } else {
      text += `; ${el}`;
    }
  }
  return text;
}

export default {
  components: {
    Loader,
    RiskIcon,
    SortedIcon,
  },
  data() {
    return {
      isLoading: false,
      jobs: [],
      // table
      columns: [
        ['Title', 'name'],
        ['Compounds', 'compounds'],
        ['Conc. (nM)', 'concentrations'],
        ['Outcome', 'risk'],
        ['Created', 'created_at'],
        ['Status', 'status'],
      ],
      searchString: '',
      awaitingSearch: false, // for debounce
      tableRows: [],
      sortKey: 'created_at',
      sortDirection: 'desc',
      // export of data
      showingExportModal: false,
      isExportLoading: false,
      // jobs deletion
      showingDeleteModal: false,
      deleteJobId: null,
    };
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
            query getJobs($product_name: ProductName!) {
              jobs: getJobs(product_name: $product_name) {
                _id
                name
                created_at
                status
                tasks {
                  input
                  output
                }
              }
            }
          `,
          variables: {
            product_name: config.productName,
          },
        });
        this.jobs = response.data.jobs;
      } catch (error) {
        this.$utils.alertError(error);
        throw error;
      }
      this.augmentJobs();
      this.assembleRows();
      this.isLoading = false;
    },
    augmentJobs() {
      this.jobs.forEach((job) => {
        // list of compounds
        job.compounds = joinAndAbbreviate(job.tasks.map((task) => task.input.compound).sort());
        // list of concentrations (for jobs that only contains one task/compound)
        if (job.tasks.length === 1) {
          job.concentrations = joinAndAbbreviate(job.tasks[0].input.concentrations.sort((c1, c2) => c1 - c2), true);
        } else {
          job.concentrations = '';
        }
        // mark as not expanded by default
        job.isExpanded = false;
        // if there is only one task and one concentration, no need to be able to expand
        if (job.tasks.length === 1 && job.tasks[0].input.concentrations.length === 1) {
          job.isSingle = true;
          job.risk = job.tasks[0].output[0].risk;
        } else {
          job.risk = -1; // for sorting
        }
      });
    },
    assembleRows() {
      const search = this.searchString.toLowerCase();
      const rows = [];
      this.jobs.forEach((job) => {
        const matchingTitle = job.name.toLowerCase().indexOf(search) > -1;
        const matchingSomeTask = job.tasks.some((task) => task.input.compound.toLowerCase().indexOf(search) > -1);
        if (search === '' || matchingTitle || matchingSomeTask) {
          rows.push(job);
          if (job.isExpanded) {
            job.tasks.forEach((task) => {
              const matchingThisTask = task.input.compound.toLowerCase().indexOf(search) > -1;
              if (search === '' || matchingTitle || matchingThisTask) {
                task.output.forEach((output) => {
                  rows.push({
                    name: job.name,
                    compounds: task.input.compound,
                    concentrations: output.concentration.toString(),
                    risk: output.risk,
                    created_at: job.created_at,
                    status: job.status,
                    error: output.warning && output.warning.warning,
                  });
                });
              }
            });
          }
        }
      });
      this.tableRows = rows;
    },
    toggleExpand(id) {
      this.jobs.forEach((job) => {
        // eslint-disable-next-line no-underscore-dangle
        if (job._id === id) {
          job.isExpanded = !job.isExpanded;
        }
      });
      this.assembleRows();
    },
    sortBy(sortBy) {
      // if s == current sort, reverse
      if (sortBy === this.sortKey) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = sortBy;
    },
    toggleDeleteConfirmationModal(jobId = null) {
      if (jobId != null) {
        this.deleteJobId = jobId;
      }
      this.showingDeleteModal = !this.showingDeleteModal;
    },
    async deleteSimulation() {
      this.toggleDeleteConfirmationModal();
      let deleteJobArrayIndex = null;
      const remainingJobs = this.jobs.filter((job, index) => {
        // eslint-disable-next-line no-underscore-dangle
        if (job._id === this.deleteJobId) {
          deleteJobArrayIndex = index;
          return false;
        }
        return true;
      });

      try {
        this.jobs[deleteJobArrayIndex].isLoading = true; // for presentation purposes
        await this.$apollo.mutate({
          mutation: gql`
          mutation deleteJob($product_name: ProductName!, $job_id: ID!) {
            job: deleteJob(product_name: $product_name, job_id: $job_id)
          }`,
          variables: {
            product_name: config.productName,
            job_id: this.deleteJobId,
          },
        });
      } catch (error) {
        this.jobs[deleteJobArrayIndex].isLoading = false;
        console.error(error);
      }
      this.jobs = remainingJobs;
      this.assembleRows();
    },
    // DATA EXPORT
    toggleExportModal() {
      this.showingExportModal = !this.showingExportModal;
    },
    async exportCSV() {
      const jsonData = await this.fetchAndPrepareForExport();
      const csv = Papa.unparse(jsonData);
      const fileName = 'qttdp_computations.csv';
      const fileToSave = new Blob([csv], {
        type: 'text/csv',
        name: fileName,
      });
      this.showingExportModal = false;
      saveAs(fileToSave, fileName);
    },
    async exportJSON() {
      const jsonData = await this.fetchAndPrepareForExport();
      const fileName = 'qttdp_computations.json';
      const fileToSave = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: 'application/json',
        name: fileName,
      });
      this.showingExportModal = false;
      saveAs(fileToSave, fileName);
    },
    async fetchAndPrepareForExport() {
      this.isExportLoading = true;
      const response = await this.$apollo.query({
        query: gql`
          query getJobs($product_name: ProductName!) {
            jobs: getJobs(product_name: $product_name) {
              name
              tasks {
                input
                output
              }
            }
          }
        `,
        variables: {
          product_name: config.productName,
        },
      });
      const { jobs } = response.data;
      const computations = [];
      const search = this.searchString.toLowerCase();
      jobs.forEach((job) => {
        const matchingTitle = job.name.toLowerCase().indexOf(search) > -1;
        job.tasks.forEach((task) => {
          const matchingThisTask = task.input.compound.toLowerCase().indexOf(search) > -1;
          if (search === '' || matchingTitle || matchingThisTask) {
            delete task.input.concentrations;
            task.output.forEach((output) => {
              delete output.debug;
              delete output.risk;
              const oneComputation = {};
              oneComputation.title = job.name;
              Object.assign(oneComputation, task.input);
              Object.assign(oneComputation, output);
              computations.push(oneComputation);
            });
          }
        });
      });
      this.isExportLoading = false;
      return computations;
    },
  },
  mounted() {
    this.fetch();
  },
  watch: {
    searchString() {
      const debounceMilliSeconds = 300;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.assembleRows();
          this.awaitingSearch = false;
        }, debounceMilliSeconds);
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    sortedTableRows() {
      const modifier = this.sortDirection === 'asc' ? 1 : -1;
      if (this.sortKey === 'name' || this.sortKey === 'compounds') {
        return this.tableRows.slice().sort((a, b) => {
          if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase()) return -modifier;
          if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase()) return modifier;
          return 0;
        });
      }
      if (this.sortKey !== 'concentrations') {
        // general case
        return this.tableRows.slice().sort((a, b) => {
          if (a[this.sortKey] < b[this.sortKey]) return -modifier;
          if (a[this.sortKey] > b[this.sortKey]) return modifier;
          return 0;
        });
      }
      return this.tableRows.slice().sort((a, b) => {
        // special case for concentrations as they are presented as list
        // which presents some implications for sorting
        const concentrationsA = a.concentrations.split(', ');
        const concentrationsB = b.concentrations.split(', ');
        const indexA = this.sortDirection === 'asc' ? 0 : concentrationsA.length - 1;
        const indexB = this.sortDirection === 'asc' ? 0 : concentrationsB.length - 1;
        const concentrationA = parseFloat(concentrationsA[indexA]);
        const concentrationB = parseFloat(concentrationsB[indexB]);
        const leftNaN = Number.isNaN(concentrationA);
        const rightNaN = Number.isNaN(concentrationB);
        if (leftNaN && rightNaN) { // sort rows with several compounds to end (they dont show concentrations so NaN)
          return 0;
        }
        if (leftNaN) {
          return 1;
        }
        if (rightNaN) {
          return -1;
        }
        if (concentrationA < concentrationB) return -modifier;
        if (concentrationA > concentrationB) return modifier;
        return 0;
      });
    },
  },
};
</script>

<style scoped>
th.sortable {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
.flex-vertical-center-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
p.legend {
  margin-bottom: 1em;
}
.help-icon {
  cursor: help;
  margin-left: 0.1em;
  margin-right: 0.1em;
}
.channel-warning-icon {
  color: darkorange;
}
.warning-icon-margin {
  margin-right: 0.2em;
  margin-left: 0.5em;
}
</style>

<template>
  <div class="columns">
    <div class="column is-6">
      <div class="upload">
        <label class="label">
          <span v-if="status !== ''" class="icon is-normal" v-bind:class="iconClass">
            <font-awesome-icon v-bind:icon="icon" />
          </span>
          Upload
          <font-awesome-icon icon="info-circle" style="cursor: help;" v-bind:title="help" />
        </label>
        <div class="file has-name is-fullwidth">
          <label class="file-label">
            <input
              class="file-input"
              v-bind:name="refName"
              type="file"
              v-bind:ref="refName"
              v-on:change="handleFileUpload()"
            />
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon icon="upload" />
              </span>
              <span class="file-label">Choose a file…</span>
            </span>
            <span class="file-name my-file-name">{{ filename }}</span>
          </label>
        </div>
        <p class="help is-large with-space">
          File must be in CSV format
          <span>(</span>
          <a
            :href="`${publicPath}qttdp_example.csv`"
            target="_blank"
            rel="noopener noreferrer"
          >download example</a>).
          Hover over help icon for more information.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';

Papa.parsePromise = (file) => new Promise((complete, error) => {
  Papa.parse(
    file,
    {
      complete,
      delimiter: ',',
      error,
      header: true,
      skipEmptyLines: 'greedy',
    },
  );
});

function validateFile(file) {
  const forbiddenFilename = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$|([<>:"/\\|?*])|(\.|\s)$/ig;
  const allowedFileExtensions = ['csv', 'txt'];

  if (file) {
    if (file.name !== '') {
      if (forbiddenFilename.test(file.name)) {
        throw new Error(`Invalid filename \`${file.name}\` for upload.`);
      }
      const extension = file.name.toLowerCase().split('.').pop();
      if (!allowedFileExtensions.includes(extension)) {
        throw new Error(`File of type \`${extension}\` not accepted. Must be one of ${allowedFileExtensions.join(', ')}.`);
      }
    } else {
      throw new Error('Filename empty.');
    }
  } else {
    throw new Error('File not found.');
  }
}

function validateHeaders(headers, expectedHeaders) {
  // We compare headers in lower case to be lenient with user.
  const lowerHeaders = headers.map((h) => h.toLowerCase());
  const lowerExpectedHeaders = expectedHeaders.map((h) => h.toLowerCase());
  const translation = {};
  for (let i = 0; i < lowerExpectedHeaders.length; i += 1) {
    const expected = lowerExpectedHeaders[i];
    if (!lowerHeaders.includes(expected)) {
      throw new Error(`Error parsing uploaded file. Could not find header \`${expectedHeaders[i]}\` in first line of CSV:<br /><pre>${headers}</pre>`);
    }
    const j = lowerHeaders.indexOf(expected);
    translation[expectedHeaders[i]] = headers[j];
  }
  return translation;
}

function validateRows(rows, translatedHeaders, errors) {
  if (rows.length === 0) {
    throw new Error('Expecting at least one row of data.');
  }
  const errorMessages = [];
  for (let i = 0; i < errors.length; i += 1) {
    const e = errors[i];
    if (e.code === 'TooManyFields') {
      errorMessages.push(`Found extra data in line ${e.row + 2} for compound \`${rows[e.row][translatedHeaders.Compound]}\`. Did you mean to add concentrations? Use semicolons between test concentrations.`);
    } else if (e.code === 'TooFewFields') {
      errorMessages.push(`${e.message} in line ${e.row + 2} for compound \`${rows[e.row][translatedHeaders.Compound]}\`.`);
    } else {
      errorMessages.push(`${e.message} in line ${e.row + 2}.`);
    }
  }
  if (errorMessages.length > 0) throw new Error(errorMessages.join('<br />'));
}

export default {
  computed: {
    icon() {
      return this.status === 'ok' ? 'check' : 'times';
    },
    iconClass() {
      return this.status === 'ok' ? 'has-text-success' : 'has-text-danger';
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    async handleFileUpload() {
      const [file] = this.$refs[this.refName].files;
      this.$emit('upload', file);
      try {
        validateFile(file);
        const parsed = await Papa.parsePromise(file);
        const rows = parsed.data;
        const { errors } = parsed;
        const headers = parsed.meta.fields;
        const translatedHeaders = validateHeaders(headers, this.headers);
        validateRows(rows, translatedHeaders, errors);
        this.$emit('uploadAccept', rows, translatedHeaders);
      } catch (error) {
        this.$emit('uploadReject');
        this.$utils.alertError(error);
      }
    },
  },
  props: {
    headers: Array,
    help: String,
    filename: String,
    refName: String,
    status: String,
  },
};
</script>

<style scoped>
div.upload {
  margin-top: 0.5em;
}
</style>

<template>
  <div id="app">
    <div class="main-flex">
      <NavigationBar />
      <router-view />
    </div>
    <footer class="footer bottom-flex has-background-white-ter">
      <div class="container has-text-centered">
        <p>
          <strong>QT/TdP Risk Screen</strong> by
          <a href="https://www.insilicotrials.com">InSilicoTrials Technologies</a>
          <br />
          <router-link :to="{name: 'terms'}">Terms and Conditions</router-link>&nbsp;apply.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';

export default {
  components: {
    NavigationBar,
  },
};
</script>

<style lang="scss">

/* Custom fonts (https://transfonter.org/) */

@font-face {
    font-family: 'Bw Gradual DEMO';
    src: url('./assets/fonts/BwGradualDEMO-RegularItalic.woff2') format('woff2'),
        url('./assets/fonts/BwGradualDEMO-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Gradual DEMO';
    src: url('./assets/fonts/BwGradualDEMO-Bold.woff2') format('woff2'),
        url('./assets/fonts/BwGradualDEMO-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Gradual DEMO';
    src: url('./assets/fonts/BwGradualDEMO-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/BwGradualDEMO-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Gradual DEMO';
    src: url('./assets/fonts/BwGradualDEMO-Regular.woff2') format('woff2'),
        url('./assets/fonts/BwGradualDEMO-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Bulma customisation */
$body-size: 18px;
/* Custom colour palette. Must be defined before importing Bulma. */
$primary: #ffddc8; // Peach
$link: #3703ff; // Deep Blue
$info: #ffddc8; // Peach
$success: #1b998b;
$warning: #e3b505;
$danger: #ee4266;
$light: #ffffff; // White
$dark: #3703ff; // Deep Blue
// Table
$table-head-background-color: #a7e5ff; // Crystal
$table-row-hover-background-color: #e7f8ff; // Light Crystal
$table-striped-row-even-hover-background-color: #e7f8ff; // Light Crystal
// Tabs
$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;
$tabs-toggle-link-active-color: hsl(0, 0%, 29%); // Gray Dark
// Steps
$steps-completed-color: $dark;
$steps-active-color: $dark;
// Fonts
$family-primary: "Bw Gradual DEMO", "Arial", sans-serif;

@import "~bulma/bulma.sass";
@import "~bulma-steps-component/bulma-steps.sass";

/* CSS for sticky footer from https://css-tricks.com/couple-takes-sticky-footer/ */

html {
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-flex {
  flex: 1 0 auto;
  background-color: #f4f4f4; // Dust
}

.bottom-flex {
  flex-shrink: 0;
}

div.custom-container {
  background-color: white;
  padding: 1.5em;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 0px;
  box-shadow: 0px 0px 1em hsl(0%, 0%, 86%);
}

div.ist-container {
  @extend .container;
  background-color: white;
  padding: 1.5em;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 0px;
  box-shadow: 0px 0px 1em hsl(0%, 0%, 86%);
}

ul {
  list-style: disc outside;
  padding-left: 1em;
}

ol {
  list-style: decimal outside;
  padding-left: 1em;
}

ul > li,
ol > li {
  margin-bottom: 0.25em;
}

div.with-space {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

button.with-space,
a.with-space {
  margin-top: 0.75em;
}

p.with-space {
  margin-bottom: 0.5em;
}

table.ist-table {
  @extend .table;
  border: 1px solid #eeeeee;
  margin: auto;
}

table.ist-table-striped {
  @extend .ist-table;
  @extend .is-striped;
}

table.ist-table td,
th {
  vertical-align: middle !important;
  text-align: center !important;
}

table.ist-table td.left,
th.left {
  text-align: left !important;
}
</style>

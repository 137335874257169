<template>
  <span class="icon">
    <font-awesome-icon v-show="showOrNot" v-bind:icon="iconWithDirection" />
  </span>
</template>

<script>
export default {
  computed: {
    showOrNot() {
      return this.currentSort === this.thisSort;
    },
    iconWithDirection() {
      if (this.direction === 'asc') {
        return 'arrow-down';
      }
      return 'arrow-up';
    },
  },
  props: {
    direction: String,
    currentSort: String,
    thisSort: String,
  },
};
</script>

<style>
</style>

<template>
  <div
    class="modal modal-fix-position"
    :class="{'is-active': active}"
  >
    <div
      class="modal-background"
      @click="close"
    />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ title }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="close"
        />
      </header>
      <section class="modal-card-body">
        <slot />
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          :class="actionClass"
          @click="action"
        >
          {{ actionLabel }}
        </button>
        <button
          class="button"
          @click="close"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    title: {
      type: String,
      default: 'Message',
    },
    actionLabel: {
      type: String,
      default: 'Okay',
    },
    actionClass: {
      type: String,
      default: 'is-primary',
    },
  },
  emits: ['action', 'close'],
  methods: {
    action() {
      this.$emit('action');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-fix-position {
  position: fixed;
}
</style>

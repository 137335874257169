<template>
  <font-awesome-layers class="fa-lg fix-valign" v-bind:class="{'breathe': withSpace}">
    <font-awesome-icon icon="circle" v-bind:class="outcomeClass(risk)" />
    <font-awesome-icon v-bind:icon="outcomeIcon(risk)" transform="shrink-6" inverse />
  </font-awesome-layers>
</template>

<script>
export default {
  methods: {
    outcomeIcon(risk) {
      if (risk >= 5) return 'times';
      return 'check';
    },
    outcomeClass(risk) {
      if (risk >= 7) return 'has-text-danger';
      if (risk >= 5) return 'has-text-warning';
      if (risk >= 3) return 'probably-safe';
      return 'safe';
    },
  },
  props: {
    risk: Number,
    withSpace: Boolean,
  },
};
</script>

<style>
.safe {
  color: #1d8579;
}
.probably-safe {
  color: #6eb2ff;
}
.fix-valign {
  vertical-align: text-bottom;
}
.breathe {
  margin-left: 0.5em;
  margin-right: 0.2em;
}
</style>

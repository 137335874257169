<template>
  <div class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child box">
                <NewSimulation />
              </div>
              <div class="tile is-child box">
                <Support />
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <LatestActivity />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <HowToUse />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <Documentation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Documentation from '@/components/Home/Documentation.vue';
import HowToUse from '@/components/Home/HowToUse.vue';
import LatestActivity from '@/components/Home/LatestActivity.vue';
import NewSimulation from '@/components/Home/NewSimulation.vue';
import Support from '@/components/Home/Support.vue';

export default {
  components: {
    Documentation,
    HowToUse,
    LatestActivity,
    NewSimulation,
    Support,
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
  name: 'home',
};
</script>

<template>
  <div class="section">
    <div class="ist-container">
      <h1 class="title">New Computation</h1>
      <StepIndicator
        v-model="step"
        v-bind:labels="stepLabels"
        v-bind:warnings="stepWarnings"
      />
      <!-- step 0: setup -->
      <div v-if="step === 0">
        <div class="columns">
          <div class="column is-6">
            <Field
              v-model="title"
              id="s0-title"
              label="Title"
              v-bind:validate="highestVisitedStep > 0"
            />
            <FieldRadio
              v-model="inputMode"
              id="s0-inputMode"
              label="Select input option"
              v-bind:options="modeOptions"
            />
          </div>
        </div>
        <StepButtons v-model="step" />
      </div>
      <!-- step 1/1 for upload mode -->
      <div v-else-if="step === 1 && inputMode === 'upload'">
        <h2 class="title is-4">Compound List Upload</h2>
        <Upload
          v-bind:filename="filename"
          v-bind:status="uploadStatus"
          refName="upload"
          v-bind:headers="csvHeaders"
          help="Upload a CSV file where the fields are delimited by commas.
The first line of the file should contain the column headers. They are:

    Compound,IKrIC50,Hill_Kr,IKsIC50,Hill_Ks,ICaLIC50,Hill_CaL,INaLIC50,Hill_NaL,Concentrations

The following lines can then include the computation data. For example:

    CompoundABC,1000,1.1,2000,1.2,3000,1.3,4000,1.4,10000;100000

The IC50 values and test concentrations are expressed in nM.
The test concentrations are separated from each other by semicolons.

Columns for IC50 values and Hill coefficients can be left empty in case experimental data for the
corresponding channel is not available. For example:

    CompoundNoCaL,10,1,20,2,,,40,4,0.1;0.2;0.3"
          v-on:upload="handleUpload"
          v-on:uploadAccept="handleUploadAccept"
          v-on:uploadReject="handleUploadReject"
        />
        <StepButtons v-model="step" />
      </div>
      <!-- step 1/2 for manual mode: input of in-vitro data for one compound -->
      <div v-else-if="step === 1 && inputMode === 'manual'">
        <h2 class="title is-4">Compound</h2>
        <div class="columns">
          <div class="column is-6">
            <Field
              v-model="job[0].compound"
              id="s1-compound"
              label="Name"
              v-bind:validate="highestVisitedStep > 1"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <Channel
              name="Kr"
              v-model="job[0].channels.Kr"
              v-bind:validate="highestVisitedStep > 1"
            />
          </div>
          <div class="column">
            <Channel
              name="Ks"
              v-model="job[0].channels.Ks"
              v-bind:validate="highestVisitedStep > 1"
            />
          </div>
          <div class="column">
            <Channel
              name="CaL"
              v-model="job[0].channels.CaL"
              v-bind:validate="highestVisitedStep > 1"
            />
          </div>
          <div class="column">
            <Channel
              name="NaL"
              v-model="job[0].channels.NaL"
              v-bind:validate="highestVisitedStep > 1"
            />
          </div>
        </div>
        <StepButtons v-model="step" />
      </div>
      <!-- step 2/2 for manual mode: input of test concentrations -->
      <div v-else-if="step === 2 && inputMode === 'manual'">
        <h2 class="title is-4">Concentrations</h2>
        <div class="columns">
          <div class="column is-6">
            <Concentrations
              v-model="strConcentrations"
              v-bind:concentrations="job[0].concentrations"
              v-on:concentrationsUpdate="handleConcentrationsUpdate"
              v-bind:validate="highestVisitedStep > 2"
            />
          </div>
        </div>
        <StepButtons v-model="step" />
      </div>
      <!-- final step (either 2 or 3): summary and submission -->
      <div v-else>
        <h2 class="title is-4">Summary</h2>
        <SummaryItem label="Title" v-bind:item="title" />
        <div class="table-container summary-table">
          <table class="ist-table-striped">
            <thead>
              <tr>
                <th></th>
                <th
                  v-for="(_, key, i) in job[0].channels"
                  v-bind:key="i"
                  colspan="2"
                >
                  <span>I</span>
                  <sub>{{ key }}</sub>
                </th>
                <th></th>
              </tr>
              <tr>
                <th class="left">Compound</th>
                <template v-for="(_, i) in Array(4).fill(0)">
                  <th v-bind:key="i + 'IC50'">
                    <span>IC</span>
                    <sub>50</sub> (nM)
                  </th>
                  <th v-bind:key="i + 'Hill'">Hill</th>
                </template>
                <th class="left">Concentrations (nM)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(task, i) in job" v-bind:key="i">
                <td
                  class="left"
                  v-bind:class="{ 'is-warning': !task.compound.ok }"
                >
                  {{ task.compound.value }}
                </td>
                <template v-for="(ch, j) in task.channels">
                  <td
                    v-bind:key="`${i}-${j}-IC50`"
                    v-bind:class="{ 'is-warning': !ch.IC50.ok }"
                  >
                    {{ ch.IC50.value | localeNumber }}
                  </td>
                  <td
                    v-bind:key="`${i}-${j}-Hill`"
                    v-bind:class="{ 'is-warning': !ch.Hill.ok }"
                  >
                    {{ displayHill(ch) }}
                  </td>
                </template>
                <td
                  class="left"
                  v-bind:class="{ 'is-warning': !allGood(task.concentrations) }"
                >
                  {{ displayConcentrations(task.concentrations) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="height-with-button">
          The computation will consume {{ tokenCounter }}
          <span>token</span>
          <span v-if="tokenCounter > 1">s</span>.
          <span
            class="tag is-medium"
            v-bind:class="{ 'is-warning': tokenCounter > tokensAvailable }"
            >{{ tokensAvailable }} tokens available</span
          >
        </p>
        <div class="level submit-level">
          <div class="level-left">
            <div class="level-item">
              <button
                class="button"
                id="s3-submit"
                v-bind:class="{
                  'is-warning': !allowedToSubmit,
                  'is-success': allowedToSubmit,
                }"
                v-bind:disabled="isSubmitting || !allowedToSubmit"
                v-on:click="submitJob"
              >
                <Loader v-bind:isLoading="isSubmitting" />Run Computation
              </button>
            </div>
            <div class="level-item">
              <span v-if="tokenCounter > tokensAvailable"
                >Not enough available tokens.</span
              >
            </div>
            <div class="level-item">
              <span v-if="!jobValidates" class="height-with-button"
                >Please fix highlighted inputs.</span
              >
            </div>
          </div>
        </div>
        <StepButtons v-model="step" isLast />
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config/apollo-config';
import gql from 'graphql-tag';
import Channel from '@/components/Simulation/Channel.vue';
import Concentrations from '@/components/Simulation/Concentrations.vue';
import Field from '@/components/Simulation/Field.vue';
import FieldRadio from '@/components/Simulation/FieldRadio.vue';
import Loader from '@/components/Loader.vue';
import StepButtons from '@/components/Simulation/StepButtons.vue';
import StepIndicator from '@/components/Simulation/StepIndicator.vue';
import SummaryItem from '@/components/Simulation/SummaryItem.vue';
import Upload from '@/components/Simulation/Upload.vue';

function allGood(arr) {
  return arr.length > 0 && arr.every((obj) => obj.ok);
}

function hasValue(valueObject) {
  return valueObject.value != null && valueObject.value !== '';
}

function text(value) {
  const ok = value != null && value !== '';
  return { value, ok, checks: 1 };
}

function positiveNumber(value) {
  const ok = !(value == null || value === '' || Number.isNaN(Number(value)) || value <= 0);
  return { value, ok, checks: 1 };
}

function positiveNumbers(strValues) {
  const values = [];
  strValues.split(';').forEach((v) => values.push(positiveNumber(v)));
  return values;
}

function optionalPositiveNumber(value) {
  let ok = true;
  if (value !== '') {
    ok = !(value == null || Number.isNaN(Number(value)) || value <= 0);
  }
  return { value, ok, checks: 1 };
}

function newEmptyJob() {
  return [{
    compound: { value: '', ok: false, checks: 0 },
    concentrations: [],
    channels: {
      Kr: {
        IC50: { value: '', ok: true, checks: 0 },
        Hill: { value: '', ok: true, checks: 0 },
      },
      Ks: {
        IC50: { value: '', ok: true, checks: 0 },
        Hill: { value: '', ok: true, checks: 0 },
      },
      CaL: {
        IC50: { value: '', ok: true, checks: 0 },
        Hill: { value: '', ok: true, checks: 0 },
      },
      NaL: {
        IC50: { value: '', ok: true, checks: 0 },
        Hill: { value: '', ok: true, checks: 0 },
      },
    },
  }];
}

export default {
  components: {
    Channel,
    Concentrations,
    Field,
    FieldRadio,
    Loader,
    StepButtons,
    StepIndicator,
    SummaryItem,
    Upload,
  },
  computed: {
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    filename() {
      if (this.upload !== '') return this.upload.name;
      return '';
    },
    stepLabels() {
      return this.modeOptions[this.inputMode].steps;
    },
  },
  data() {
    return {
      // configuration of wizard
      step: 0,
      highestVisitedStep: 0, // up to which step to run validation
      inputMode: 'manual',
      modeOptions: {
        manual: {
          label: 'manual input for a single compound',
          steps: ['Setup', 'In vitro data', 'Concentrations', 'Run'],
        },
        upload: {
          label: 'file upload for one or more compounds',
          steps: ['Setup', ' Upload', 'Run'],
        },
      },
      csvHeaders: [
        'Compound',
        'IKrIC50',
        'Hill_Kr',
        'IKsIC50',
        'Hill_Ks',
        'ICaLIC50',
        'Hill_CaL',
        'INaLIC50',
        'Hill_NaL',
        'Concentrations',
      ],

      // user data
      title: { value: '', ok: false, checks: 0 },
      upload: '',
      job: newEmptyJob(),
      strConcentrations: '',

      // tokens
      tokensAvailable: 0,
      tokenCounter: 0,

      // form status
      isSubmitting: false,
      uploadStatus: '',
      jobValidates: false,
      stepWarnings: [false, false, false],
    };
  },
  methods: {
    allGood,
    displayConcentrations(arr) {
      return arr.map((obj) => {
        if (obj.ok) return parseFloat(obj.value).toLocaleString('en-US');
        return obj.value;
      }).join('; ');
    },
    displayHill(ch) {
      if (hasValue(ch.Hill)) return ch.Hill.value;
      if (hasValue(ch.IC50)) return '1';
      return '';
    },
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: config.productName,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    handleConcentrationsUpdate(arr) {
      this.job[0].concentrations = arr;
      this.tokenCounter = arr.length;
    },
    handleUpload(file) {
      this.upload = file;
    },
    handleUploadAccept(inputs, translatedHeaders) {
      let tokens = 0;
      const job = [];
      inputs.forEach((row) => {
        const task = {};
        task.compound = text(row[translatedHeaders.Compound]);
        task.concentrations = positiveNumbers(row[translatedHeaders.Concentrations]);
        task.channels = {};
        ['Kr', 'Ks', 'CaL', 'NaL'].forEach((channel) => {
          const headerIC50 = `I${channel}IC50`;
          const headerHill = `Hill_${channel}`;
          task.channels[channel] = {
            IC50: optionalPositiveNumber(row[translatedHeaders[headerIC50]]),
            Hill: optionalPositiveNumber(row[translatedHeaders[headerHill]]),
          };
        });
        job.push(task);
        tokens += row.Concentrations.split(';').length;
      });
      this.job = job;
      this.tokenCounter = tokens;
      this.uploadStatus = 'ok';
      this.validateJob();
    },
    handleUploadReject() {
      this.tokenCounter = 0;
      this.job = newEmptyJob();
      this.uploadStatus = 'error';
    },
    async submitJob() {
      this.isSubmitting = true;
      try {
        const tasks = [];
        this.job.forEach((userInput) => {
          const task = {
            compound: userInput.compound.value,
            concentrations: userInput.concentrations.map((c) => parseFloat(c.value)),
            IKrIC50: null,
            IKrHill: null,
            IKsIC50: null,
            IKsHill: null,
            ICaLIC50: null,
            ICaLHill: null,
            INaLIC50: null,
            INaLHill: null,
          };
          ['Kr', 'Ks', 'CaL', 'NaL'].forEach((channel) => {
            if (userInput.channels[channel].IC50.value !== '') {
              task[`I${channel}IC50`] = parseFloat(userInput.channels[channel].IC50.value);
              task[`I${channel}Hill`] = userInput.channels[channel].Hill.value === '' ? 1 : parseFloat(userInput.channels[channel].Hill.value);
            }
          });
          tasks.push(task);
        });

        if (window.Cypress) {
          // During E2E testing, input data is saved to global window object for retrieval.
          window.testSimulationInputs = tasks;
        }

        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation addQttdpJobAndTasks($product_name: ProductName!, $tasks: [QttpdTaskIn]!, $job_name: String!) {
            job: addQttdpJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
              _id
            }
          }`,
          variables: {
            product_name: config.productName,
            job_name: this.title.value,
            tasks,
          },
        });
        // eslint-disable-next-line no-underscore-dangle
        const jobId = response.data.job._id;
        if (!window.Cypress) {
          window.setTimeout(() => {
            this.$router.push({ name: 'job', params: { id: jobId } });
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    },
    validateJob() {
      let valid = true;
      const stepWarnings = Array(this.stepLabels.length).fill(false);

      // validate title
      if (!this.title.ok) {
        valid = false;
        stepWarnings[0] = true;
      }
      // check that there is at least one task to run
      if (this.job.length < 1) {
        valid = false;
        stepWarnings[1] = true;
      }
      // validate tasks
      for (let i = 0; i < this.job.length; i += 1) {
        // 1. validate compound name
        const { compound } = this.job[i];
        if (!compound.ok) {
          valid = false;
          stepWarnings[1] = true;
        }
        // 2. validate IC50 and Hill parameter values
        const { channels } = this.job[i];
        const keys = Object.keys(channels);
        for (let j = 0; j < keys.length; j += 1) {
          // can't use forEach without passing `valid` through, so for loop instead
          const ch = channels[keys[j]];
          if (hasValue(ch.Hill) && !hasValue(ch.IC50)) {
            // We do not accept a value for the Hill parameter of a channel
            // if its corresponding IC50 value is not defined as well
            ch.IC50.checks += 1;
            ch.IC50.ok = false;
            ch.Hill.checks += 1;
            valid = false;
            stepWarnings[1] = true;
          }
          if (!hasValue(ch.Hill) && !hasValue(ch.IC50) && !ch.IC50.ok) {
            // Fix bug. To reproduce:
            // - fill out Hill coefficient for a channel but not IC50 value
            // - run validation (go to last step of new simulation page)
            // --> IC50 value will be marked as  missing
            // - remove value for Hill coefficient
            // --> IC50 still marked as missing but not needed anymore
            ch.IC50.ok = true;
          }
          if (!ch.IC50.ok || !ch.Hill.ok) {
            valid = false;
            stepWarnings[1] = true;
          }
        }
        // 3. validate concentrations
        const { concentrations } = this.job[i];
        if (!allGood(concentrations)) {
          valid = false;
          stepWarnings[this.stepLabels.length - 2] = true;
        }
      }
      this.jobValidates = valid;
      this.stepWarnings = stepWarnings;
    },
  },
  mounted() {
    this.fetchTokens();
  },
  watch: {
    // eslint-disable-next-line func-names, object-shorthand
    inputMode: function () {
      this.highestVisitedStep = 0;
      this.upload = '';
      this.uploadStatus = '';
      this.strConcentrations = '';
      this.tokenCounter = 0;
      this.job = newEmptyJob();
      this.stepWarnings = Array(this.stepLabels.length).fill(false);
    },
    // eslint-disable-next-line func-names, object-shorthand
    step: function (newStep) {
      if (newStep > this.highestVisitedStep) this.highestVisitedStep = newStep;
      if (newStep === this.stepLabels.length - 1) this.validateJob();
    },
  },
};
</script>

<style scoped>
.summary-table {
  margin-top: 2em;
  margin-bottom: 3em;
}
thead > tr:first-child > th:nth-child(n + 1) {
  border-right: 1px solid #dbdbdb !important;
}
thead > tr:last-child > th:nth-child(2n + 1) {
  border-right: 1px solid #dbdbdb !important;
}
th {
  border-bottom-style: none !important;
}
td:empty::after {
  content: "empty";
  visibility: hidden;
}
div.submit-level {
  margin-top: 1em;
}
</style>

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v1-migration.md
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
import Vue from 'vue';
import * as msal from '@azure/msal-browser';
import config from '../config/iam-config';
import router from '../router';
import store from '../store';

const clientApplication = new msal.PublicClientApplication(config);

const login = async () => {
  if (router.currentRoute.name !== 'landing') {
    router.replace({ name: 'landing' });
  }
  clientApplication.loginRedirect({
    scopes: config.scopes,
  });
};

const logout = async () => {
  clientApplication.logout();
};

const checkUser = () => {
  const accounts = clientApplication.getAllAccounts();
  if (accounts != null && accounts.length > 0) {
    const user = accounts[0];
    if (user != null) {
      if (store.state.iam.user == null) {
        store.dispatch('iam/save', user);
      }
      return true;
    }
  }
  store.dispatch('iam/save', null);
  return false;
};

const getAccessToken = async () => {
  const userAccount = clientApplication.getAccountByHomeId(store.state.iam.user.homeAccountId);
  const request = {
    scopes: config.scopes,
    account: userAccount,
  };
  let accessToken = null;
  try {
    const response = await clientApplication.acquireTokenSilent(request);
    if (!response.accessToken || response.accessToken === '') {
      throw new msal.InteractionRequiredAuthError();
    }
    accessToken = response.accessToken;
  } catch (error) {
    if (error instanceof msal.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return clientApplication.acquireTokenRedirect(request);
    }
    console.error(error);
    if (Vue.prototype.$utils != null) {
      Vue.prototype.$utils.alertError(error);
    }
  }
  return accessToken;
};

clientApplication.handleRedirectPromise().then((response) => {
  checkUser();
  if (response != null) { // happens directly following a login
    router.push({ name: 'home' }); // this is a workaround as promise only resolves after routing is done
  }
}).catch((error) => {
  if (error.errorMessage.startsWith('AADB2C90118')) { // https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview#linking-user-flows
    clientApplication.loginRedirect({
      scopes: config.scopes,
      authority: config.resetPasswordFlow,
    });
  } else if (Vue.prototype.$utils != null) {
    Vue.prototype.$utils.alertError(error.errorMessage);
  } else {
    console.error(error.errorMessage);
  }
});

export {
  login,
  logout,
  checkUser,
  getAccessToken,
};

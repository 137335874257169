<template>
  <div class="howtouse">
    <h1 class="title">How to Use</h1>
    <p class="has-text-justified with-space">
      QT/TdP Risk Screen computations of a compound’s proarrhythmic potential
      risk require the
      <span class="is-italic">in vitro</span>&#32;
      <span>IC</span>
      <sub>50</sub> and Hill coefficient values for the four ion channels
      <span>I</span>
      <sub>Kr</sub>,
      <span>I</span>
      <sub>Ks</sub>,
      <span>I</span>
      <sub>CaL</sub>, and
      <span>I</span>
      <sub>NaL</sub>, and the concentration values to be tested. The tool can
      also provide risk assessments based on data of the individual channels
      alone or different combinations thereof. In absence of experimental
      values, the user can assign to the Hill coefficients fields a default
      value of 1.
    </p>
    <ul>
      <li>
        <a
          :href="`${publicPath}QTTdP_User_Manual.pdf`"
          target="_blank"
          rel="noopener noreferrer"
          >User Manual</a
        >
      </li>
      <li>
        <a
          :href="`${publicPath}qttdp_example.csv`"
          target="_blank"
          rel="noopener noreferrer"
          >Example CSV file</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style scoped>
div.howtouse {
  padding-right: 2em;
}
</style>

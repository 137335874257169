import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboardCheck,
  faDesktop,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileDownload,
  faFilePdf,
  faFileUpload,
  faFlag,
  faGlobe,
  faInfoCircle,
  faHourglassStart,
  faHourglassHalf,
  faMagnet,
  faPencilAlt,
  faPhone,
  faSearch,
  faTemperatureLow,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpload,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import moment from 'moment';
import Buefy from 'buefy';
import Utils from './plugins/utils';
import App from './App.vue';
import router from './router';
import store from './store';
import apolloProvider from './lib/apollo';
import SimpleModal from './components/SimpleModal.vue';

library.add(
  faArrowDown,
  faArrowUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboardCheck,
  faDesktop,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileDownload,
  faFilePdf,
  faFileUpload,
  faFlag,
  faGlobe,
  faInfoCircle,
  faHourglassStart,
  faHourglassHalf,
  faMagnet,
  faPencilAlt,
  faPhone,
  faSearch,
  faTemperatureLow,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpload,
  faUser,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('SimpleModal', SimpleModal);

Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
});
Vue.use(Utils);

Vue.config.productionTip = false;

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY HH:mm');
  }
  return '-';
});

Vue.filter('threeDigits', (value) => Number.parseFloat(value).toFixed(3));

Vue.filter('localeNumber', (value) => {
  if (value == null || value === '') {
    return '';
  }
  return parseFloat(value).toLocaleString('en-US'); // comma as thousands separator
});

Vue.filter('relativeTime', (value) => {
  if (value) {
    return moment(String(value)).fromNow();
  }
  return '-/-';
});

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');

<template>
  <div class="section">
    <div class="container custom-container">
      <h1 class="title">
        <Loader v-bind:isLoading="isLoading" />Account
      </h1>
      <p v-if="errorMessage !== ''">{{errorMessage}}</p>
      <div v-else>
        <p>
          <span class="has-text-weight-bold">Status:</span>
          {{status}}
        </p>
        <p class="breatheTop">
          <span class="has-text-weight-bold">Expires:</span>
          {{expiration_datetime | formatDate}}
        </p>
        <p class="breatheTop">
          <span class="has-text-weight-bold">Available Tokens:</span>
          <span class="tag is-light tagSpaceLeft">{{tokens_available}} tokens</span>
        </p>
        <p class="breatheTop">
          <span class="has-text-weight-bold">Used:</span>
          {{tokens_used}} of {{total_tokens}}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.breatheTop {
  padding-top: 0.5em;
}
.tagSpaceLeft {
  margin-left: 0.5em;
}
</style>

<script>
import config from '@/config/apollo-config';
import gql from 'graphql-tag';
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: false,
      errorMessage: '',
      expiration_datetime: '',
      status: '',
      tokens_available: 0,
      tokens_used: 0,
      total_tokens: 0,
    };
  },
  methods: {
    async requestAccountDetails() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              product_name
              total_tokens
              tokens_used
              tokens_available
              status
              expiration_datetime
            }
          }`,
          variables: {
            product_name: config.productName,
          },
        });
        const { account } = response.data;
        if (account != null) {
          this.expiration_datetime = account.expiration_datetime;
          this.status = account.status;
          this.tokens_available = account.tokens_available;
          this.tokens_used = account.tokens_used;
          this.total_tokens = account.total_tokens;
        } else {
          this.errorMessage = 'No subscription information found. Please get in touch with a commercial representative.';
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.requestAccountDetails();
  },
};
</script>

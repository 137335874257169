<template>
  <div style="margin-left: 0.75em">
    <button
      class="button is-primary"
      :disabled="isLoading"
      @click="showModal"
    >
      <Loader
        v-if="isLoading"
        :is-loading="isLoading"
      />
      <font-awesome-icon
        v-else
        class="icon-with-margin"
        icon="file-pdf"
      />
      Report
    </button>
    <simple-modal
      :active="isActive"
      title="Generate PDF Report"
      action-label="Download"
      @action="generateReport"
      @close="closeModal"
    >
      <p style="margin-bottom: 1em;">
        You may add content to be included in the generated report.
      </p>
      <div class="field">
        <label class="label">Introduction (optional)</label>
        <p class="control">
          <textarea
            class="textarea"
            v-model="introduction"
            placeholder="Some optional introductory text..."
          ></textarea>
        </p>
      </div>
      <div class="field">
        <label class="label">Discussion (optional)</label>
        <p class="control">
          <textarea
            class="textarea"
            v-model="discussion"
            placeholder="An optional discussion..."
          ></textarea>
        </p>
      </div>
      <div class="field">
        <label class="label">Conclusion (optional)</label>
        <p class="control">
          <textarea
            class="textarea"
            v-model="conclusion"
            placeholder="An optional conclusion..."
          ></textarea>
        </p>
      </div>
    </simple-modal>
  </div>
</template>

<script>
import Loader from './Loader.vue';

export default {
  components: {
    Loader,
  },
  props: {
    isLoading: Boolean,
  },
  emits: ['generateReport'],
  data() {
    return {
      isActive: false,
      // user content
      introduction: '',
      discussion: '',
      conclusion: '',
    };
  },
  methods: {
    showModal() {
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
    },
    generateReport() {
      // TODO: Check inputs/encode
      const userContent = {};
      if (this.introduction !== '') {
        userContent.introduction = `# Introduction\n${this.introduction}\n\n`;
      }
      if (this.discussion !== '') {
        userContent.discussion = `# Discussion\n${this.discussion}\n\n`;
      }
      if (this.conclusion !== '') {
        userContent.conclusion = `# Conclusion\n${this.conclusion}\n\n`;
      }
      this.$emit('generateReport', userContent);
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.icon-with-margin {
  margin-right: 0.5em;
}
</style>

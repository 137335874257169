import Vue from 'vue';
import axios from 'axios';
import API_ENDPOINT from '../config/remote-calls-config';
import { getAccessToken } from './iam';

async function remoteCall(method, endpoint, path, config) {
  try {
    const accessToken = await getAccessToken();
    const axiosConfig = {
      method,
      url: endpoint + path,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (config !== null) {
      // this can override existing values in axios_config
      Object.assign(axiosConfig, config);
    }
    return axios(axiosConfig);
  } catch (error) {
    console.error(error);
    Vue.prototype.$utils.alertError(error);
    throw error;
  }
}

async function apiCall(method, path, config = null) {
  return remoteCall(method, API_ENDPOINT, path, config);
}

export default apiCall;

<template>
  <div class="card full-height has-background-white-ter">
    <div class="card-content full-height">
      <div class="media full-height">
        <div class="media-left flex-vertical-center-parent full-height">
          <span class="fa-stack fa-2x">
            <font-awesome-icon icon="circle" class="fa-stack-2x" />
            <font-awesome-icon v-bind:icon="icon" class="fa-stack-1x fa-inverse" />
          </span>
        </div>
        <div class="media-content">
          <div class="feature-contents">
            <h2 class="title is-5">{{ title }}</h2>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
  },
};
</script>

<style>
.full-height {
  height: 100%;
}
.feature-contents {
  padding-right: 1em;
}
.flex-vertical-center-parent {
  display: flex;
  align-items: center;
}
</style>

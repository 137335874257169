<template>
  <div class="section">
    <div class="container custom-container">
      <div v-if="job !== null">
        <span v-if="isActive" class="tag is-warning is-medium is-pulled-right">
          <MiniLoader v-bind:isLoading="true" />active
        </span>
        <p class="buttons is-pulled-right">
          <button class="button is-primary" v-on:click="toggleExportModal">
            <font-awesome-icon class="icon-with-margin" icon="download" />
            Data
          </button>
          <ReportModal
            v-if="job.status == 'completed'"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
        </p>
        <h1 id="job-title" class="title is-4">{{ job.name }}</h1>
        <h2 class="subtitle is-6">{{ job.created_at | formatDate }}</h2>
        <div v-if="isShowingInputs">
          <p class="with-space">
            Overview Input Data
            <a
              v-if="isShowingInputs"
              v-on:click="toggleExpansion"
              href="#"
              class="is-uppercase is-size-7"
              >&nbsp;Hide inputs</a
            >
          </p>
          <div class="table-container">
            <table id="inputs-table" class="table ist-table is-striped">
              <colgroup>
                <col class="border-right" />
                <col />
                <col class="border-right" />
                <col />
                <col class="border-right" />
                <col />
                <col class="border-right" />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr class="my-tr">
                  <th colspan="1" class="has-text-centered"></th>
                  <th colspan="2" class="has-text-centered">
                    <span>I</span>
                    <sub>Kr</sub>
                  </th>
                  <th colspan="2" class="has-text-centered">
                    <span>I</span>
                    <sub>Ks</sub>
                  </th>
                  <th colspan="2" class="has-text-centered">
                    <span>I</span>
                    <sub>CaL</sub>
                  </th>
                  <th colspan="2" class="has-text-centered">
                    <span>I</span>
                    <sub>NaL</sub>
                  </th>
                </tr>
                <tr>
                  <th class="left">Compound</th>
                  <th>
                    <span>IC</span>
                    <sub>50</sub>
                    &nbsp;(nM)
                  </th>
                  <th>Hill</th>
                  <th>
                    <span>IC</span>
                    <sub>50</sub>
                    &nbsp;(nM)
                  </th>
                  <th>Hill</th>
                  <th>
                    <span>IC</span>
                    <sub>50</sub>
                    &nbsp;(nM)
                  </th>
                  <th>Hill</th>
                  <th>
                    <span>IC</span>
                    <sub>50</sub>
                    &nbsp;(nM)
                  </th>
                  <th>Hill</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(task, index) in job.tasks" v-bind:key="index">
                  <td class="wide left">{{ task.input.compound }}</td>
                  <td class="wide">{{ task.input.IKrIC50 | localeNumber }}</td>
                  <td class="wide">{{ task.input.IKrHill }}</td>
                  <td class="wide">{{ task.input.IKsIC50 | localeNumber }}</td>
                  <td class="wide">{{ task.input.IKsHill }}</td>
                  <td class="wide">{{ task.input.ICaLIC50 | localeNumber }}</td>
                  <td class="wide">{{ task.input.ICaLHill }}</td>
                  <td class="wide">{{ task.input.INaLIC50 | localeNumber }}</td>
                  <td class="wide">{{ task.input.INaLHill }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="with-space space-above">
          Overview Results
          <a
            data-cy="show-inputs"
            v-if="!isShowingInputs"
            v-on:click="toggleExpansion"
            href="#"
            class="is-uppercase is-size-7"
            >&nbsp;Show inputs</a
          >
        </p>
        <div class="table-container">
          <table id="results-table" class="table ist-table is-striped">
            <colgroup>
              <col />
              <col class="border-right" />
              <col span="5" />
              <col class="border-right" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th class="left">Compound</th>
                <th class="left">Conc.&nbsp;(nM)</th>
                <th>
                  <span>Tx</span>
                  <sub>Ks</sub>
                </th>
                <th>
                  <span>Tx</span>
                  <sub>NaL</sub>
                </th>
                <th>
                  <span>TqNet</span>
                  <sub>Ks</sub>
                </th>
                <th>
                  <span>TqNet</span>
                  <sub>NaL</sub>
                </th>
                <th>
                  <span>Ttriang</span>
                  <sub>Ks</sub>
                </th>
                <th>
                  <span>Ttriang</span>
                  <sub>NaL</sub>
                </th>
                <th class="left">
                  Outcome
                  <font-awesome-icon
                    title="Outcome follows classification in Llopis et al. 2020."
                    icon="info-circle"
                    class="help-icon"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="task in job.tasks">
                <tr
                  v-for="output in task.output"
                  v-bind:key="task.input.compound + output.concentration"
                >
                  <td class="left">{{ task.input.compound }}</td>
                  <td class="left">
                    {{ output.concentration | localeNumber }}
                  </td>
                  <template v-if="output.warning && output.warning.warning">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="left">
                      <font-awesome-icon
                        class="channel-warning-icon"
                        icon="exclamation-triangle"
                      />
                      <span
                        v-for="(channel, index) in output.warning.channels"
                        v-bind:key="channel"
                      >
                        <span v-if="index > 0">,&nbsp;</span>
                        <span>I</span>
                        <sub>{{ channel }}</sub>
                      </span>
                    </td>
                  </template>
                  <template v-else>
                    <td>{{ output.Tx_Ks | threeDigits }}</td>
                    <td>{{ output.Tx_NaL | threeDigits }}</td>
                    <td>{{ output.TqNet_Ks | threeDigits }}</td>
                    <td>{{ output.TqNet_NaL | threeDigits }}</td>
                    <td>{{ output.Ttriang_Ks | threeDigits }}</td>
                    <td>{{ output.Ttriang_NaL | threeDigits }}</td>
                    <td class="left">
                      <RiskIcon v-bind:risk="output.risk" />
                      &nbsp;
                      {{ output.outcome }}
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <p v-if="showWarningLegend" class="with-more-space">
          <font-awesome-icon
            class="channel-warning-icon"
            icon="exclamation-triangle"
          />Data out of range
        </p>
        <!-- modal for export of simulation data -->
        <b-modal
          :active.sync="showingExportModal"
          trap-focus
          aria-role="dialog"
          aria-modal
          has-modal-card
        >
          <div class="modal-background" />
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Export Data</p>
              <button
                class="delete"
                aria-label="close"
                v-on:click="toggleExportModal"
              />
            </header>
            <section class="modal-card-body">
              Chose format in which to export data.
            </section>
            <footer class="modal-card-foot">
              <button
                class="button is-primary"
                v-on:click="exportCSV"
                :disabled="isExportLoading"
              >
                Export to CSV
              </button>
              <button
                class="button is-primary"
                v-on:click="exportJSON"
                :disabled="isExportLoading"
              >
                Export to JSON
              </button>
              <span v-if="isExportLoading">
                <Loader v-bind:isLoading="isExportLoading" />&nbsp;Preparing...
              </span>
            </footer>
          </div>
        </b-modal>
      </div>
      <div v-else>
        <h1 class="title"><Loader v-bind:isLoading="isLoading" />Results</h1>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import config from '@/config/apollo-config';
import Loader from '@/components/Loader.vue';
import MiniLoader from '@/components/MiniLoader.vue';
import ReportModal from '@/components/ReportModal.vue';
import RiskIcon from '@/components/RiskIcon.vue';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import apiCall from '../lib/remote-calls';

export default {
  components: {
    Loader,
    ReportModal,
    MiniLoader,
    RiskIcon,
  },
  data() {
    return {
      isLoading: false,
      isActive: false, // for when computation is still running
      job: null,
      isShowingInputs: false,
      // export of data
      showingExportModal: false,
      isExportLoading: false,
      isPdfLoading: false,
    };
  },
  computed: {
    showWarningLegend() {
      let showLegend = false;
      // eslint-disable-next-line no-labels, no-restricted-syntax
      outerLoop:
      for (let i = 0; i < this.job.tasks.length; i += 1) {
        for (let j = 0; j < this.job.tasks[i].output.length; j += 1) {
          if (this.job.tasks[i].output[j].warning && this.job.tasks[i].output[j].warning.warning) {
            showLegend = true;
            // eslint-disable-next-line no-labels
            break outerLoop;
          }
        }
      }
      return showLegend;
    },
  },
  methods: {
    toggleExpansion() {
      this.isShowingInputs = !this.isShowingInputs;
    },
    async fetch() {
      const jobId = this.$route.params.id;
      this.isLoading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
            query getJob($product_name: ProductName!, $job_id: ID!) {
              job: getJob(product_name: $product_name, job_id: $job_id) {
                name
                created_at
                status
                tasks {
                  input
                  output
                }
              }
            }
          `,
          variables: {
            product_name: config.productName,
            job_id: jobId,
          },
        });
        this.job = response.data.job;
        if (this.job.status === 'active') {
          this.isActive = true;
          window.setTimeout(this.fetch, 5000);
        } else {
          this.isActive = false;
        }
        // this.job.tasks[0].output[0].warning = { warning: true, channels: ['Kr', 'CaL'] };
      } catch (error) {
        console.error('error', error);
      }
      this.isLoading = false;
    },
    // PDF DOWNLOAD
    async downloadPdf(userContent) {
      this.isPdfLoading = true;
      // user content
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });
      // request pdf
      const response = await apiCall('POST', 'api/pdf-export-func', {
        responseType: 'blob',
        header: { Accept: 'application/json' },
        data: {
          template: 'qttdp',
          tokens: [
            ...userContentTokens,
            { key: 'title', type: 'string', data: this.job.name },
            { key: 'date', type: 'string', data: this.$options.filters.formatDate(this.job.created_at) },
            {
              key: 'inputsTable',
              type: 'table',
              data: {
                header: ['Compound', 'I~Kr~ IC~50~', 'Hill', 'I~Ks~ IC~50~', 'Hill', 'I~CaL~ IC~50~', 'Hill', 'I~NaL~ IC~50~', 'Hill'],
                rows: this.job.tasks.map((t) => [
                  t.input.compound,
                  this.$options.filters.localeNumber(t.input.IKrIC50),
                  t.input.IKrHill,
                  this.$options.filters.localeNumber(t.input.IKsIC50),
                  t.input.IKsHill,
                  this.$options.filters.localeNumber(t.input.ICaLIC50),
                  t.input.ICaLHill,
                  this.$options.filters.localeNumber(t.input.INaLIC50),
                  t.input.INaLHill,
                ]),
              },
            },
            {
              key: 'outputTables',
              type: 'tables',
              data: this.job.tasks.map((task) => ({
                caption: `Outcome for ${task.input.compound}`,
                header: ['Conc.', 'Tx~Ks~', 'Tx~NaL~', 'TqNet~Ks~', 'TqNet~NaL~', 'Ttriang~Ks~', 'Ttriang~NaL~', 'Outcome'],
                rows: task.output.map((output) => {
                  if (output.warning && output.warning.warning) {
                    return [
                      this.$options.filters.localeNumber(output.concentration),
                      '',
                      '',
                      '',
                      '',
                      '',
                      '',
                      `! I~${output.warning.channels.join('~, I~')}~`,
                    ];
                  }
                  return [
                    this.$options.filters.localeNumber(output.concentration),
                    this.$options.filters.threeDigits(output.Tx_Ks),
                    this.$options.filters.threeDigits(output.Tx_NaL),
                    this.$options.filters.threeDigits(output.TqNet_Ks),
                    this.$options.filters.threeDigits(output.TqNet_NaL),
                    this.$options.filters.threeDigits(output.Ttriang_Ks),
                    this.$options.filters.threeDigits(output.Ttriang_NaL),
                    output.outcome,
                  ];
                }),
              })),
            },
            { key: 'dataWarning', type: 'string', data: this.showWarningLegend ? '! Data out of range for channels shown.' : '' },
          ],
        },
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'QTTdP_Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
    // DATA EXPORT
    toggleExportModal() {
      this.showingExportModal = !this.showingExportModal;
    },
    async exportCSV() {
      const jsonData = await this.fetchAndPrepareForExport();
      const csv = Papa.unparse(jsonData);
      const fileName = 'qttdp_computations.csv';
      const fileToSave = new Blob([csv], {
        type: 'text/csv',
        name: fileName,
      });
      this.showingExportModal = false;
      saveAs(fileToSave, fileName);
    },
    async exportJSON() {
      const jsonData = await this.fetchAndPrepareForExport();
      const fileName = 'qttdp_computations.json';
      const fileToSave = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: 'application/json',
        name: fileName,
      });
      this.showingExportModal = false;
      saveAs(fileToSave, fileName);
    },
    async fetchAndPrepareForExport() {
      this.isExportLoading = true;
      const thisJob = JSON.parse(JSON.stringify(this.job));
      const computations = [];
      thisJob.tasks.forEach((task) => {
        delete task.input.concentrations;
        task.output.forEach((output) => {
          output.concentration_nM = output.concentration;
          delete output.concentration;
          delete output.risk;
          delete output.debug;
          delete output.warning;
          const oneComputation = {};
          oneComputation.title = thisJob.name;
          Object.assign(oneComputation, task.input);
          Object.assign(oneComputation, output);
          computations.push(oneComputation);
        });
      });
      this.isExportLoading = false;
      return computations;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
col.border-right {
  border-right: 1px solid #dbdbdb;
}
.my-tr > th {
  border-style: none;
}
p.space-above {
  margin-top: 1em;
}
td.wide {
  min-width: 4em;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.channel-warning-icon {
  margin-right: 0.3em;
  color: darkorange;
  /*border-right-color: ;
  border-radius: 4px;
  padding: 1px 4px 4px 4px;*/
}
p.with-more-space {
  margin-bottom: 1em;
}
.help-icon {
  cursor: help;
  margin-left: 0.25em;
}
</style>

import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
// import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import store from '../store';
import apolloConfig from '../config/apollo-config';
import { getAccessToken } from './iam';

// Install the vue plugin
Vue.use(VueApollo);

// TODO createUploadLink -
/* const httpLink = createUploadLink({
  uri: httpEndpoint,
  ...httpLinkOptions,
}) */

// Get GraphQL results over a network using HTTP fetch
const httpLink = createHttpLink({
  uri: apolloConfig.url,
});

// HTTP Auth header injection
const authLink = setContext(async (_, { headers }) => {
  try {
    let authorizationHeader = {};
    const user = await store.state.iam.user;
    if (user) {
      const token = await getAccessToken();
      authorizationHeader = token ? { authorization: `Bearer ${token}` } : {};
    }
    return {
      headers: {
        ...headers,
        ...authorizationHeader,
      },
    };
  } catch (error) {
    Vue.prototype.$utils.alertError(error);
    throw error;
  }
});

// Concat all the http link parts
const link = authLink.concat(httpLink);

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  resolvers: {
    Query: {
      testLocalQuery() {
        return true;
      },
    },
  },
});

// Create the apollo provider
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;

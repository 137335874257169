<template>
  <div>
    <div class="field">
      <label class="label">
        <span>Enter one or more test concentrations separated by semicolons (nM).</span>
        <font-awesome-icon icon="info-circle" title="For example 1;2;3 or 5.50;23.45;10000" class="help-icon" />
      </label>
      <p class="control">
        <input
          type="text"
          class="input"
          id="s2-concentrations"
          v-bind:value="value"
          v-on:input="onInput"
          v-bind:class="{ 'is-warning': hasWarning }"
        />
      </p>
      <p class="help">
        <span class="has-text-warning" v-if="hasWarning">
          <font-awesome-icon icon="exclamation-triangle" class="warning-icon" />
          &nbsp;{{ errorMessage }}
        </span>
      </p>
    </div>
    <p>Concentrations check:</p>
    <ul v-if="concentrations.length>0" class="with-space">
      <li v-for="(v, index) in concentrations" :key="index" v-bind:class="{ 'has-text-warning': !v.ok && v.checks>0 }">
        {{v.value}}
        <span class="has-text-warning" v-if="!v.ok && v.checks>0">
          <font-awesome-icon icon="exclamation-triangle" />
        </span>
      </li>
    </ul>
    <p v-if="value.length === 0">
      <span class="is-italic">None</span>
    </p>
  </div>
</template>

<script>

function allGood(arr) {
  return arr.length > 0 && arr.every((obj) => obj.ok);
}

export default {
  computed: {
    hasWarning() {
      return this.validate && !allGood(this.concentrations);
    },
    errorMessage() {
      if (this.value === '') return 'Missing input.';
      return 'Invalid input.';
    },
  },
  methods: {
    allGood,
    onInput(event) {
      const { value } = event.target;
      const concentrations = [];
      value.split(';').forEach((v) => {
        if (v === '') return;
        const ok = v !== '' && !(Number.isNaN(Number(v)) || v <= 0);
        concentrations.push({
          value: v, ok, checks: 1,
        });
      });
      this.$emit('input', value);
      this.$emit('concentrationsUpdate', concentrations);
    },
  },
  props: {
    value: String,
    concentrations: Array,
    validate: Boolean,
  },
};
</script>

<style scoped>
.help-icon {
  cursor: help;
  margin-left: 0.25em;
}
</style>

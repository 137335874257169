<template>
  <nav id="NavigationBar" class="navbar is-dark" v-bind:class="{'with-shadow': !isFullPage}">
    <div class="container">
      <div class="navbar-brand">
        <a href="https://www.insilicotrials.com" class="navbar-item navbar-logo">
          <img src="../assets/ist_logo_white.png" />
        </a>
        <a
          role="button"
          v-on:click="toggleBurger"
          class="navbar-burger burger"
          v-bind:class="{ 'is-active': isBurger }"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div class="navbar-menu" v-bind:class="{ 'is-active': isBurger }">
        <div class="navbar-start"></div>
        <div class="navbar-end">
          <router-link :to="{name: 'landing'}" class="navbar-item has-text-weight-bold">QT/TdP Risk Screen</router-link>
          <router-link :to="{name: 'home'}" class="navbar-item" v-if="user">Home</router-link>
          <router-link :to="{name: 'activity'}" class="navbar-item" v-if="user">Activity</router-link>
          <router-link :to="{name: 'account'}" class="navbar-item" v-if="user">Account</router-link>
          <span class="navbar-item">
            <button class="button is-info" v-if="!user" @click="login">
              <span>Login</span>
            </button>
            <button class="button is-white is-outlined" v-else @click="logout">
              <span>Logout</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import { login, logout } from '@/lib/iam';

export default {
  computed: {
    isFullPage() {
      return this.$route.matched.some((record) => record.meta.fullPage);
    },
    ...mapState('iam', {
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      isBurger: false,
    };
  },
  methods: {
    toggleBurger() {
      this.isBurger = !this.isBurger;
    },
    login,
    logout,
  },
};
</script>

<style>
#NavigationBar {
  border-radius: 0;
  border-bottom: 0px;
  height: 4.5em;
}
.with-shadow {
  box-shadow: 0px 0px 1em gray;
}
.navbar-logo > img {
  max-height: 2em !important;
}
</style>

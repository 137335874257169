<template>
  <p class="buttons">
    <button class="button is-primary with-space" v-on:click="setStep(value-1)" v-if="value>0">
      <span class="icon is-small">
        <font-awesome-icon icon="chevron-left" />
      </span>
      <span>Previous</span>
    </button>
    <button class="button is-primary with-space" v-on:click="setStep(value+1)" v-if="!isLast">
      <span>Next</span>
      <span class="icon is-small">
        <font-awesome-icon icon="chevron-right" />
      </span>
    </button>
  </p>
</template>

<script>
export default {
  methods: {
    setStep(step) {
      this.$emit('input', step);
    },
  },
  props: {
    value: Number,
    isLast: Boolean,
  },
};
</script>

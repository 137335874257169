<template>
  <div class="field field-radio">
    <label class="label">{{ label }}</label>
    <div class="control">
      <div v-for="(opt, key, index) in options" v-bind:key="index">
        <label class="radio">
          <input
            type="radio"
            v-bind:id="id"
            v-bind:name="id"
            v-bind:value="key"
            v-bind:checked="key==value"
            v-on:change="$emit('input', $event.target.value)"
          />
          {{opt.label}}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    label: String,
    value: String,
    options: Object,
  },
};
</script>

<style scoped>
.field-radio {
  margin-top: 1.5em;
}
label {
  margin-bottom: 0.5em;
}
input {
  margin-right: 0.5em;
}
</style>

<template>
  <section class="section">
    <div class="container custom-container">
      <h1 class="title">Terms and Conditions</h1>
      <h2 class="title is-4">Art. 1 – Parties and Object</h2>This agreement is between InSilicoTrials Technologies S.r.l. («the
      Company» or «the supplier») and you («the Customer» or «the Client»).
      Upon this Agreement, InSilicoTrials agrees to enable you to use its
      modeling and simulation software (the «Software») in accordance with the
      terms and conditions set out below. The Software that InSilicoTrials
      provides uses data, settings and other information that the Customer
      uploads in an Account the Customer creates. The Customer agrees to pay
      the requested fee for the services offered by InSilicoTrials’ Software
      according to the provisions set out below.
      <h2 class="title is-4 breatheTop">Art. 2 – Access to QT/TdP Risk Screen</h2>
      <ol>
        <li>
          You need to create your own Account on the QT/TdP Risk Screen
          website to access the Software.
        </li>
        <li>You can create your account by clicking on the “Registration” link.</li>
        <li>
          You will be asked to enter your personal information, your e-mail
          address, and a password of your choice.
        </li>
        <li>
          Click the “Register” button. This action will submit your data, will
          provide consent to the InSilicoTrials Terms and Conditions and
          Privacy Policy.
        </li>
        <li>
          An e-mail will then be sent to you at the e-mail address previously
          entered. Your account will be created by clicking on the “Confirm”
          button.
        </li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 3 – Data necessary for use</h2>
      <ol>
        <li>
          InSilicoTrials uses a Customer account (hereinafter: Account) to
          record the data necessary for invoicing and ensures that the
          Customer can use the Software through the Account. InSilicoTrials
          expressly points out the fact that the data required for payment of
          the usage fee are not stored by InSilicoTrials, but rather by
          companies accredited for this purpose, in order to ensure the
          security of this information.
        </li>
        <li>
          In order to be able to use the Software, the Customer shall
          personally upload or specify the data necessary to perform a
          computational modeling and simulation run and review its
          completeness and correctness.
        </li>
        <li>
          The Customer is solely responsible for the correctness and
          completeness of the data transmitted by the Customer and the
          modelling of the technical system. The Software can only perform a
          calculation correctly using completely transmitted and correct data.
          InSilicoTrials therefore assumes no liability for the correctness of
          the calculated results with regard to the process to be simulated.
        </li>
        <li>
          The Customer can access the data loaded into the Account at any
          time, and can access the results obtained by the use of the Software
          at any time.
        </li>
        <li>
          Should the Software and related services be temporarily unable to
          provide these data and results for any technical reasons, then
          InSilicoTrials aims to remedy the malfunction promptly. This
          excludes events of force majeure. InSilicoTrials shall promptly
          inform the Customer in any case of temporary inability due to the
          uploading or remedying or maintenance of the Software. In any case,
          the Customer accepts the risk of non-availability or temporary
          dysfunction of the Software and related services.
        </li>
        <li>The Customer can freely use the results of the usage.</li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 4 – Right of use</h2>InSilicoTrials permits the Customer to use the Software developed by
      InSilicoTrials through a browser (e.g. Google Chrome, Mozilla Firefox,
      Microsoft Internet Explorer and Edge, Apple Safari) installed on the
      Customer’s electronic devices connected via internet and computers or on
      the electronic devices made available by InSilicoTrials. The Customer is
      entitled to have the data and settings uploaded or made by employees.
      <h2 class="title is-4 breatheTop">Art. 5 – No transfer of the right of use</h2>
      <ol>
        <li>
          The Customer is not entitled to transfer the right of use to third
          parties.
        </li>
        <li>
          The Customer is not authorized to use the Software for third parties
          through his Account. In case of unauthorized usage, InSilicoTrials
          is entitled to block the Account. In this case, InSilicoTrials shall
          issue a closing invoice. In this case as well, the Customer is
          obligated to maintain the e-mail address until completion of this
          process.
        </li>
      </ol>
      <h2
        class="title is-4 breatheTop"
      >Art. 6 – InSilicoTrials Simulations Software performance and service</h2>
      <ol>
        <li>
          InSilicoTrials guarantees the use of its Software application. The
          Software is composed of various components from various providers.
        </li>
        <li>
          Data transmitted by the Customer will be forwarded by InSilicoTrials
          to computers — always in encrypted form. The use of the Software
          will be carried out on these computers. Data will be loaded to the
          web server from the Customer’s local computer. As soon as the
          Customer starts the calculation, InSilicoTrials forwards the data
          and the settings to external computer centers; as soon as results
          are created on these computers, they are sent to the web server and
          can be called up by the Customer in his Account.
        </li>
        <li>
          InSilicoTrials rents computing capacity to enable the use of its
          software.
        </li>
        <li>
          InSilicoTrials sends the data to available computers so that the
          available resources can be used as efficiently as possible. Due to
          the central management of this process, InSilicoTrials is able to
          precisely calculate the usage fee under Art. 7 below.
        </li>
        <li>
          Save what provided by Privacy Policy and Terms and Conditions,
          InSilicoTrials does not use the data uploaded by the Customer.
        </li>
      </ol>
      <p>
        Separately, the platform sends reports about errors in the program’s
        application flow through the InSilicoTrials program used by the
        Customer. The Customer consents to InSilicoTrials using these data
        exclusively for optimizing the program.
      </p>
      <ol>
        <li>
          According to what provided by Privacy Policy and Terms and
          Conditions, InSilicoTrials takes customary precautionary measures in
          order to guarantee the security and confidentiality of the
          customer’s data.
        </li>
        <li>
          InSilicoTrials guarantees the use of the software immediately after
          opening of the account or activation of specific options, regardless
          of any assertion of a right of withdrawal. InSilicoTrials hereby
          provides notice of the possibility of charging a fee for services
          provided up to that point.
        </li>
        <li>
          InSilicoTrials aims to be helpful and accommodating at all times,
          and will do its absolute best to assist the Customer wherever
          possible. InSilicoTrials guarantees only the equipment, software and
          services under its control. InSilicoTrials guarantees response times
          for all items related to the usage of the Software and connected
          services but doesn’t guarantee uptime for the same items.
        </li>InSilicoTrials is unable to provide guaranteed resolution times; this
        is because the nature and causes of problems can vary enormously. The
        Company is deemed to have responded when it has replied to the
        Customer’s initial request. This may be in the form of an e-mail or
        telephone call, to either provide a solution or request further
        information.
      </ol>
      <h2 class="title is-4 breatheTop">Art.7 – Usage Fee</h2>
      <ol>
        <li>
          All prices will be provided on demand by the InSilicoTrials’ sales
          team.
        </li>
        <li>The Customer will pay the Software price.</li>
        <li>
          Upon receipt of the payment the invoice shall be sent in digital
          form to the Customer’s billing e-mail address.
        </li>
        <li>
          Deletion of the Account shall have no impact on the usage fee and
          its due date.
        </li>
        <li>For credit card payments, costs will be sustained by the Customer.</li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 8 – Notice of Right to withdrawal</h2>
      <ol>
        <li>
          The Customer has the right to withdraw this Agreement within
          fourteen days without providing reasons. The withdrawal period
          starts on the date the account is opened and runs for fourteen days.
          To exercise the right of withdrawal, the Customer can send a clear
          statement (e.g., a letter sent by mail or an e-mail) indicating the
          decision to withdraw this Agreement to “InSilicoTrials Technologies
          S.r.l., Via Flavia 23/1 – 34148 Trieste” or to this e-mail address:
          customerservice@insilicotrials.com. If the Customer takes advantage
          of this possibility, then we will transmit a confirmation of receipt
          of such a withdrawal without undue delay.
        </li>
        <li>
          To comply with the deadline for withdrawal, it is sufficient to send
          notice of exercise of the right of withdrawal prior to expiration of
          the cancellation period that will terminate and cease at 11,59.59 pm
          of the fourteenth day.
        </li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 10 – Limitation of warranty</h2>
      <ol>
        <li>
          If the Software made available has an error, then InSilicoTrials
          shall have the right to remedy of defects. The Customer shall have a
          right to withdraw if InSilicoTrials cannot remedy the error in the
          simulation within a reasonable period. These provisions shall apply
          subject to the limitation of liability and warranty within this
          regulation.
        </li>
        <li>
          As long as the Customer uses the software free of charge,
          InSilicoTrials shall only be liable for wrongful intent and gross
          negligence.
        </li>
        <li>
          When a fee is paid for use, InSilicoTrials warrants the
          functionality of the Software provided for that use, with the
          provision that software and configuration defects [that technically
          cannot be excluded (e.g., bugs)] will be removed in each case as
          soon as possible upon their discovery and previous notice to
          InSilicoTrials. Otherwise, liability for damage and consequential
          damage due to defects is limited to wrongful intent and gross
          negligence.
        </li>
        <li>
          Any and all liability is excluded for the modelling of the technical
          system since this falls beyond InSilicoTrials’ sphere of influence.
        </li>
        <li>
          InSilicoTrials’ liability for its agents is limited to wrongful
          intent and gross negligence.
        </li>
        <li>
          Liability for data loss is limited to wrongful intent and gross
          negligence.
        </li>
        <li>
          The amount of claims for damage shall be limited in all cases to the
          value of the contract signed by the Customer for the InSilicoTrials
          services. Due to the special hazards that may result in individual
          cases from releasing applications for sale, InSilicoTrials
          recommends purchasing insurance.
        </li>
        <li>
          The inadequacies that customarily occur in InSilicoTrials’ Software
          and therefore cannot be excluded shall be kept to the lowest level
          possible by InSilicoTrials regularly revising the Software. To this
          purpose, InSilicoTrials shall previously inform the Customer for any
          period of no functionality due to revising the software or its
          maintenance. Such revision shall be carried out outside of the main
          usage periods insofar as technically possible. If the Customer is a
          commercial customer, then the Customer is obligated to promptly
          notify InSilicoTrials of defects in the Software that the Customer
          becomes aware of it. Similarly, the Customer shall provide notice of
          any defects becoming known to him in the interaction of the various
          components of the Software. In any case, InSilicoTrials shall inform
          the Customers in reasonable advance for any necessary suspension of
          any service related to the use of the Software due to its
          maintaining and updating. Any restrictions on the functionality of
          InSilicoTrials’ offerings will be limited to the minimum time as
          possible.
        </li>
        <li>
          InSilicoTrials also uses third-party software for individual parts
          of simulation runs. InSilicoTrials selects this software to the best
          of its knowledge and belief. InSilicoTrials assumes a warranty for
          the functionality of the third-party software provided for use only
          in case of wrongful intent and gross negligence, but agrees to
          report errors to the author of the software immediately after their
          discovery. If a calculated result is incorrect due to a defect that
          has subsequently been remedied, the specific calculation process can
          be repeated without a usage fee being charged.
        </li>
        <li>
          The limitation of liability does not apply to personal injury,
          damage to health, and death.
        </li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 11 – Support</h2>
      <ol>
        <li>
          The Customer may request that InSilicoTrials employees help in the
          creation of a simulation.
        </li>
        <li>
          In this case as well, InSilicoTrials excludes any and all liability
          for the correctness of the modelling of the technical system.
          InSilicoTrials can help exclusively in setting the tool, but shall
          in no case make its own assessment of what is technically necessary
          for the Customer.
        </li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 12 – Termination of the Agreement</h2>
      <ol>
        <li>
          The Customer can delete the Account when the usage fee due under
          Article 7 has been paid.
        </li>
      </ol>
      <p>
        InSilicoTrials reserves and retains the right to delete the Account in
        question after termination by a Customer or after blocking under
        provisions set out above. Save also what provided by Privacy Policy
        and terms and conditions, after deleting the Account InSilicoTrials
        will also delete all other Customer data.
      </p>
      <ol>
        <li>
          The Customer is obligated to maintain the e-mail account specified
          in the Account until InSilicoTrials has informed the Customer that
          all payments have been completed.
        </li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 13 – Severability Clause</h2>Should individual provisions be or become invalid or contestable in toto
      or in part, this shall not affect the applicability and validity of the
      other clauses set forth herein, and this provision shall be interpreted
      and/or supplemented in such a way that the intended meaning and purpose
      are achieved to the possible extent.
      <h2 class="title is-4 breatheTop">Art. 14 – Law and Jurisdiction Clause</h2>
      <ol>
        <li>
          For commercial Customers: Italian law shall be exclusively applied
          to this Agreement.
        </li>
        <li>
          For commercial Customers: The Milan Court shall have exclusive
          jurisdiction and venue for any disputes arising from and with regard
          to this Agreement.
        </li>
      </ol>
      <h2 class="title is-4 breatheTop">Art. 15 – Final Provisions</h2>All amendments shall require written form to be effective; this shall
      apply to the requirement of written form itself.
      <h1 class="title">Updated Privacy Policy (Effective as of April 10th, 2018)</h1>
      <p>
        Thank you for visiting InSilicoTrials Technologies s.r.l.
        (“InSilicoTrials”)’s website (the “Site”).
      </p>
      <p>
        Your privacy is important to us. To better protect your privacy, we
        provide this notice (the “Policy”), which applies to information
        provided or collected through this Site located at
        www.insilicotrials.com and in connection with InSilicoTrials’ various
        services and software products. If you have any questions or
        complaints regarding our Privacy Policy or practices, please feel free
        to contact us through our website or write to us at
        customerservice@insilicotrials.com
      </p>
      <h2 class="title breatheTop is-5">The Information We Collect</h2>
      <p>
        To use the InSilicoTrials’ Simulations Software and its connected
        services, you are previously requested to access our website and
        register yourself, giving us information that can identify you
        (“personal information”).
      </p>
      <p>
        We receive and store any information you enter on our website or give
        us in any other way (for example, through surveys, questionnaires,
        information provided at registration and in the course of other
        communications with us).
      </p>
      <p>
        When you register as a Customer of InSilicoTrials’ Simulations
        Software, (the “Software”) you voluntarily provide information about
        yourself (such as name and e-mail address). In addition, when you use
        the Software and/or the connected services, all the documents (e.g.
        your CAD file, your dataset etc), text and information transmitted to
        us for the analysis and other purposes are transferred to our servers.
      </p>
      <p>
        We guarantee that all reserved information about your searches and
        projects shall be used for the purposes requested by the access to the
        software and otherwise implied by us only to collect statistical
        information exclusively addressed to improve our services.
      </p>
      <p>
        When you place an order on our site our merchant services provider
        requests certain personally identifiable information on the order
        form. You must provide contact information (such as name, e-mail
        address, and billing address) and financial information (such as
        credit card number and expiration date). The merchant services
        provider processing the order will use this information for billing
        purposes to fill your orders. If there is a problem in processing an
        order, we may use the information provided at registration to contact
        you.
      </p>
      <p>
        The amount of personally identifying information you provide is up to
        you, and you are not obligated to provide such information. However,
        your use of the Site or the services may be affected if such
        information is not provided.
      </p>
      <p>
        You may provide information on behalf of another entity. If you
        complete the registration or provide information on behalf of a
        certain entity, it represents to us that you were authorized by that
        entity to give us consent to use the submitted information.
      </p>
      <p>
        We may collect certain non-personally identifying information when you
        visit our Site or use the Software or the services connected, such as
        your IP address, the type of browser you are using, the type of
        operating system you are using and access times.
      </p>
      <p>
        We also may collect information about your online activity. For more
        information, please see the Privacy and Cookies section of this
        website.
      </p>
      <p>
        The Site, the Software and services connected are also directed to a
        general audience and we do not offer services directed to children.
        Should we come to know that a child under the age of 18 has provided
        any personal information to us, we will immediately delete this
        information.
      </p>
      <h2 class="title breatheTop is-5">Customer Testimonials</h2>We may post customer testimonials on the site which may contain personal
      information. We do obtain the customers’ consent via e-mail prior to
      posting the testimonial to post their name along with their testimonial.
      To request removal of your personal information from our testimonials,
      contact us at customerservice@insilicotrials.com.
      <h2 class="title breatheTop is-5">How We Use the Information</h2>Our affiliates and us may use personal information provided to us as
      follows:
      <ul class="terms">
        <li>
          We may use your information to verify your credentials, to maintain
          reasonable security, to develop new features and services for our
          Customers, to vary advertising based on Customer preference, usage
          preferences and demographics, to develop and improve features,
          contents, and services available, to fulfill your requests for our
          products and services, in connection with your use of the Site or
          Software and/or connected services too, to respond to your inquiries
          about our services offerings, to offer you the products or services
          that we believe may be of interest to you.
        </li>
        <li>
          We will retain information transmitted to us in the context of use
          of services (such as documents like CAD File or text transmitted to
          us for analysis and correction) for further internal use in
          connection with our efforts to improve our services.
        </li>
        <li>
          Newsletters: If you wish to subscribe to our newsletter(s), we will
          use your name and e-mail address to send the newsletter to you. For
          the respect of your privacy, we provide you a way to unsubscribe by
          clicking on the “unsubscribe” link located at the bottom of each
          newsletter communication or by emailing us at
          customerservice@insilicotrials.com
        </li>
        <li>
          We may use non-personally identifying information that we collect to
          obtain statistical information about Customers of the Site or
          Software and connected services, to improve the quality, design and
          content of our Site and Software, and to analyze the use of our
          site.
        </li>
      </ul>
      <h2 class="title breatheTop is-5">Information Sharing and Disclosure</h2>
      <p>
        We will not sell, share, trade or rent your personal information to
        third parties other than as disclosed within this privacy policy.
      </p>
      <p>We may disclose your personal information to:</p>
      <ul>
        <li>
          Business partners with whom we may jointly offer products or
          services, or whose products or services may be offered on our Site
          or together with our Software. You can know when a third party is
          involved in a product or service because their name and/or logo will
          appear on it, either alone or with ours. If you choose to use these
          products or services, we may share information about you, including
          your personal information, with those partners. Please note that we
          do not control the privacy practices of these third-party business
          partners. If you wish to no longer allow that third party to use
          your information you may opt-out by contacting that third party
          directly.
        </li>
        <li>
          Companies within our corporate family that are involved in operating
          or providing the services. Our affiliates will follow practices that
          are at least as restrictive as the practices described in this
          Privacy Policy. We may share aggregated or anonymous data with third
          parties for any use whatsoever.
        </li>
      </ul>
      <p>We also may share your information:</p>
      <ul>
        <li>
          In response to court orders, or other legal process or in response
          to a law enforcement agency’s request; to establish or exercise our
          legal rights; to defend against legal claims; or as otherwise
          required by law. In such cases we reserve the right to raise or
          waive any legal objection or right available to us.
        </li>
        <li>
          Whenever we believe it is appropriate to investigate, prevent, or
          take action regarding illegal or suspected illegal activities; to
          protect and defend the rights, property, or safety of our company or
          this website, our customers, or others; and in connection with our
          Terms and Conditions for our Software and other agreements.
        </li>
        <li>
          In the event that InSilicoTrials goes through a business transition,
          such as a merger, acquisition by another company, or sale of all or
          a portion of its assets, your personally identifiable information
          will likely be among the assets transferred. You will be notified
          via email or prominent notice on our Site 30 days before any change
          of the ownership or control of your personal information.
        </li>
      </ul>
      <p>
        By providing personal information or using our Site or services, you
        consent to transfer information outside your country in connection
        with the transfers of information described in this Privacy Policy,
        even to countries that might offer a different level of protection for
        your personal information from the one offered in your country.
      </p>
      <p>
        We also may share aggregate or anonymous information with third
        parties without restriction.
      </p>
      <h2 class="title breatheTop is-5">Our Commitment to Security</h2>
      <p>
        Physical and electronic procedures have been put into place to
        safeguard the Site and help prevent unauthorized access, maintain data
        security, and correctly use personal information. These include use of
        firewalls, passwords and other measures.
      </p>
      <p>
        Although we take measures we believe are appropriate to safeguard
        against unauthorized disclosures of information, we cannot assure that
        personally identifiable information that we collect will never be
        disclosed in a manner that is inconsistent with this Policy.
        Regardless of the precautions taken by you or by us, “perfect
        security” does not exist on the Internet. We cannot ensure or warrant
        the security of any information you transmit to us, and you transmit
        such information at your own risk.
      </p>
      <p>
        When you enter sensitive information such as credit card number on our
        order form, our merchant services providers encrypt that information
        using secure socket layer technology (SSL).
      </p>
      <h2 class="title breatheTop is-5">Other Sites or Services</h2>
      <p>
        If any part of this website links you to other sites or enables the
        download of third party software, those sites, software or related
        services are not subject to this Privacy Policy.
      </p>
      <p>
        In addition, in some cases, certain services are made accessible by us
        and show our brand but are not provided by or hosted by us. We
        recommend that you examine the privacy statements posted on those
        other websites to understand their procedures for collecting, using,
        and disclosing personal information and other information, including
        their use of cookies or other technologies and contact them directly
        if you have any questions.
      </p>
      <p>
        We are not responsible for any collection or use of information in
        connection with third party software, sites or services.
      </p>
      <h2 class="title breatheTop is-5">Correcting or Updating your Information</h2>
      <p>
        InSilicoTrials allows you to view the information you provided during
        registration and to modify any data, inaccuracies or errors. To
        request such access, or to correct, update, or amend your personal
        information please contact us at the following e-mail address, postal
        address or telephone number.
      </p>
      <p>E-mail address: customerservice@insilicotrials.com</p>
      <p>Postal Address: Via Flavia 23/1, 34148 Trieste</p>
      <p>Telephone Number: +39 0284246328</p>
      <p>
        We will retain your information for as long as your account is active
        or as needed to provide you with services. If you wish to cancel your
        account or request that we no longer use your information to provide
        you services, please contact us at the e-mail address or postal
        address listed above. In any case, we will retain and use your
        information as necessary to comply with our legal obligations, resolve
        disputes, enforce our agreements. In case of cancellation, we will
        retain your data only for our internal scope (e.g. internal
        statistics, research, software improvement).
      </p>
      <h2 class="title breatheTop is-5">Communications</h2>
      <p>
        We may send you email messages about our company or our products and
        services as well as email messages about products and services of
        selected third parties, and our newsletters. By accepting the Terms
        and Conditions (including the terms of this Privacy Policy which are
        incorporated therein) or using the Site or the services, you
        affirmatively consent to receive such commercial messages.
      </p>
      <p>
        As a registered member, you can remove your personal information from
        our mailing list to stop receiving future communication from us by
        clicking on the “unsubscribe” link located at the bottom of each
        communication or by emailing us at customerservice@insilicotrials.com.
        You will also be given the opportunity to unsubscribe from commercial
        messages in any such email we send. Please note that we reserve the
        right to send you service related communications, including service
        announcements and administrative messages, related either to your
        account or to your transactions on this Site, without offering you the
        opportunity to opt out of receiving them unless you cancel your
        account.
      </p>
      <p>
        Should we decide to change our privacy policy, we will post those
        changes to this Privacy Policy on the Site home page, and other places
        we deem appropriate so that you are aware of what information we
        collect, how we use it, and under what circumstances, if any, we
        disclose it.
      </p>
      <p>
        We reserve the right to modify this privacy policy at any time, so
        please review it frequently.
      </p>
      <p>
        Should we make material changes to this policy, we will notify you
        either here, by e-mail, or by means of a notice on our home page.
      </p>
      <h2 class="title breatheTop is-5">How to Contact Us</h2>
      <p>
        Should you have any questions or concerns about this Policy, you may
        contact us at the above mentioned e-mail address or postal address.
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.breatheTop {
  padding-top: 1em;
}
ol {
  list-style: decimal inside;
}

ul.terms {
  list-style: disc inside;
}

li:not(:last-child) {
  padding-bottom: 0.25em;
}
p:not(:last-child) {
  padding-bottom: 0.25em;
}
</style>

<template>
  <div class="field">
    <label class="label">
      <span v-html="label"></span>
      <!-- v-html for sub/superscript to work -->
      &nbsp;
      <span v-if="units" v-html="unitsPresentation"></span>
      <font-awesome-icon
        v-if="help && !helpHidden"
        v-bind:title="help"
        icon="info-circle"
        class="help-icon"
      />
    </label>
    <div class="control">
      <input
        type="text"
        class="input"
        v-bind:placeholder="placeholder"
        v-bind:value="value.value"
        v-on:input="handleInput"
        v-bind:class="{ 'is-warning': hasWarning }"
        v-bind:id="id"
        v-bind:disabled="disabled"
      />
    </div>
    <p class="help">
      <span class="has-text-warning" v-if="hasWarning">
        <font-awesome-icon icon="exclamation-triangle" class="warning-icon" />
        {{ errorMessage }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    checking() {
      // We do not want every single field to show an error message due
      // to missing value on component mount, so input validation is only
      // started when told so by parent components, or upon user input.
      return this.validate || this.value.checks > 0;
    },
    hasWarning() {
      return this.checking && !this.value.ok;
    },
    unitsPresentation() {
      return `(${this.units})`;
    },
  },
  data() {
    return {
      errorMessage: 'Missing value.',
    };
  },
  methods: {
    handleInput(event) {
      const { value } = event.target;
      const ok = this.check(value);
      const checks = this.value.checks + 1;
      this.$emit('input', { value, ok, checks });
    },
    check(value) {
      if (value === '' || value == null) {
        if (this.optional) {
          this.errorMessage = '';
          return true;
        }
        this.errorMessage = 'Missing value.';
        return false;
      }
      if (this.vmin !== null || this.vmax !== null || this.vpositive) {
        if (Number.isNaN(Number(value))) {
          this.errorMessage = 'Invalid caracter - must be a number.';
          return false;
        }
        if ((this.vmin !== null && value < this.vmin)
         || (this.vmax !== null && value > this.vmax)) {
          this.errorMessage = `Value must be in range of ${Number(this.vmin)} to ${this.vmax}.`;
          return false;
        }
        if (this.vpositive && value <= 0) {
          this.errorMessage = 'Value must be > 0.';
          return false;
        }
      }
      this.errorMessage = '';
      return true;
    },
  },
  props: {
    disabled: Boolean,
    help: String,
    helpHidden: Boolean,
    id: String,
    label: String,
    optional: Boolean,
    placeholder: String,
    units: String,
    validate: Boolean,
    value: Object,
    vmin: { type: Number, default: null },
    vmax: { type: Number, default: null },
    vpositive: Boolean,
  },
  watch: {
    vmin() {
      this.check();
    },
    vmax() {
      this.check();
    },
  },
};
</script>

<style scoped>
.help-icon {
  cursor: help;
  margin-left: 0.25em;
}
.warning-icon {
  margin-right: 0.25em;
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Account from '../views/Account.vue';
import Activity from '../views/Activity.vue';
import Home from '../views/Home.vue';
import Landing from '../views/Landing.vue';
import Results from '../views/Results.vue';
import Simulation from '../views/Simulation.vue';
import Terms from '../views/Terms.vue';
import { checkUser } from '../lib/iam';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      fullPage: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Landing,
    meta: {
      fullPage: true,
      registrationFlow: true,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/jobs/new',
    name: 'simulation',
    component: Simulation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/jobs/:id',
    name: 'job',
    component: Results,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // only available during E2E tests
  if (window.Cypress) {
    next();
  } else {
    const validUser = checkUser();
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !validUser) {
      next('/');
    } else {
      next();
    }
  }
});

export default router;

<template>
  <div class="support">
    <h1 class="title support">Support</h1>
    <p>To create a new request for support please click the link below, which will open a new window.</p>
    <a
      class="button is-primary is-outlined has-text-dark with-space"
      v-bind:href="wrikeLink"
      rel="noopener noreferrer"
      target="_blank"
    >Request Support</a>
  </div>
</template>

<script>
import supportUrl from '@/config/support-config';

export default {
  data() {
    return {
      wrikeLink: supportUrl,
    };
  },
};
</script>
<style scoped>
div.support {
  padding-right: 2em;
}
h1.support {
  margin-bottom: 0.5em;
}
</style>

<template>
  <div>
    <Field v-model="value.IC50" v-bind:id="idIC50" optional vpositive v-bind:label="labelIC50" units="nM" v-bind:validate="validate" />
    <Field
      v-model="value.Hill"
      placeholder="1"
      v-bind:id="idHill"
      optional
      vpositive
      label="Hill coefficient"
      help="When no value is entered for the Hill coefficient a default value of 1 is used."
      v-bind:validate="validate"
    />
  </div>
</template>

<script>
import Field from '@/components/Simulation/Field.vue';

export default {
  components: {
    Field,
  },
  computed: {
    idIC50() {
      return `s1-${this.name}-IC50`;
    },
    labelIC50() {
      return `I<sub>${this.name}</sub> IC<sub>50</sub>`;
    },
    idHill() {
      return `s1-${this.name}-Hill`;
    },
  },
  props: {
    name: String,
    value: Object,
    validate: Boolean,
  },
};
</script>

<style scoped>
</style>

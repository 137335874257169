import { DialogProgrammatic as Dialog } from 'buefy';

function install(Vue) {
  Vue.prototype.$utils = {
    alertJson(json) {
      // https://buefy.github.io/documentation/dialog
      Dialog.alert({
        message: JSON.stringify(json),
      });
    },
    alertMessage(message, title = 'Message') {
      // https://buefy.github.io/documentation/dialog
      Dialog.alert({
        title,
        message,
      });
    },
    alertError(error) {
      let errorMsg = error;
      if (error.message) {
        errorMsg = error.message;
      }
      console.error(`alertError: ${errorMsg}`);
      // https://buefy.github.io/documentation/dialog
      Dialog.alert({
        title: 'Error',
        message: errorMsg,
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
      });
    },
  };
}

export default { install };

<template>
  <div class="step-indicator">
    <ul class="steps has-content-centered">
      <template v-for="(label, index) in labels">
        <li class="steps-segment" v-bind:class="{'is-active': index === value}" v-bind:key="index">
          <a href="#" class="steps-marker" v-on:click="onClick(index)" v-bind:class="{'is-warning': warnings[index]}">
            <span v-if="index < labels.length - 1 && warnings[index]">!</span>
            <font-awesome-icon icon="flag" v-if="index === labels.length - 1" />
          </a>
          <div class="steps-content">
            <p>{{ label }}</p>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    labels: Array,
    warnings: Array,
  },
  methods: {
    onClick(step) {
      this.$emit('input', step);
    },
  },
};
</script>

<style scoped>
.step-indicator {
  margin-top: 3em;
  margin-bottom: 1.5em;
}
ul > li {
  list-style: none;
}
</style>

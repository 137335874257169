<template>
  <div>
    <section class="section bg-colored-like-navbar">
      <div class="container has-text-centered">
        <h1 class="title is-1 has-text-light">QT/TdP Risk Screen</h1>
      </div>
    </section>
    <section class="section bg-colored">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <p
              class="is-size-3"
            >A new early screening tool that helps to detect a compound’s proarrhythmic potential and to identify its safe concentration range</p>
          </div>
          <div class="column">
            <figure class="image">
              <img src="../assets/QTTdP.jpg" />
            </figure>
          </div>
        </div>
        <div id="Features">
          <div class="columns">
            <div class="column">
              <Feature
                icon="clipboard-check"
                title="Clinically Validated"
                description="Validated on a set of 109 known drug compounds, 51 of which torsadogenic"
              />
            </div>
            <div class="column">
              <Feature
                icon="download"
                title="Data Import &amp; Export"
                description="Compound lists can be uploaded and results can be downloaded in JSON or CSV format"
              />
            </div>
            <div class="column">
              <Feature
                icon="thumbs-up"
                title="Easy to Use"
                description="Well-designed wizard that guides through the setup of the computation step by step"
              />
            </div>
          </div>
          <div id="Collaboration">
            <p class="is-size-3">
              QT/TdP Risk Screen is the result of a collaboration between
              Universitat Politècnica de València (UPV, Valencia), Fundació
              Institut Hospital del Mar D’Investigacions Mèdiques (FIMIM, Barcelona),
              and InSilicoTrials Technologies
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Feature from '@/components/Landing/Feature.vue';
import { login } from '@/lib/iam';

export default {
  components: {
    Feature,
  },
  mounted() {
    if (this.$route.matched.some((record) => record.meta.registrationFlow)) {
      login();
    }
  },
};
</script>
<style scoped>
/* Colors */

.bg-colored-like-navbar {
  background-color: #3703ff; /* Deep Blue */
  padding-top: 5em;
  padding-bottom: 3em;
}
.tinted-with-background-color {
  color: hsl(201, 34%, 96%);
}
.bg-colored {
  background-color: #f4f4f4; /* Dust */
}

/* Spacing */

h2.subtitle {
  padding-top: 0.25em;
}
#Collaboration {
  margin-top: 5em;
}
#Features {
  margin-top: 4em;
}
</style>
